import React, { Component } from "react";
import ReactTable from "react-table";
import { Grid, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as actions from "../../Store/Actions/Index";
import Card from "components/Card/Card.jsx";
import { withRouter, Link, Route, Switch, Redirect } from "react-router-dom";
import Addfee from "./AddFees";
import EditFee from "./EditFees";

class ReactTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoggedinToken: this.props.logintoken,
      tbValue: 1,
      add: false,
    };
  }

  componentDidMount = () => {
    /* Pass logintoken to fetch data */
    this.props.onGetStGenDataOps(this.state.LoggedinToken);
    this.props.onGetStSpecDataOps(this.state.LoggedinToken);
  };

  componentWillUnmount = () => {
    this.props.onReset();
  };

  /* Button Handler to switch tables */

  buttonOne = () => {
    this.setState({ tbValue: 1 }, () => {});
  };

  buttonTwo = () => {
    this.setState({ tbValue: 2 }, () => {});
  };

  addButtonHandler = () => {
    /* Redirect with corresponding data */
    if (this.state.tbValue === 1) {
      // console.log("redirecting to add fee")
      this.props.history.push({
        pathname: "/fees/addfees",
        search: `?host=${1}&category=${1}`,
      });
    }
    if (this.state.tbValue === 2) {
      this.props.history.push({
        pathname: "/fees/addfees",
        search: `?host=${1}&category=${2}`,
      });
    }
  };

  showHandler = (id) => {
    const displayData = {
      display: "show",
      fee_type_idx: id,
      LoggedinToken: this.state.LoggedinToken,
    };
    // console.log(displayData);
    this.props.changeDisplayMode(displayData);
  };

  hideHandler = (id) => {
    const displayData = {
      display: "hidden",
      fee_type_idx: id,
      LoggedinToken: this.state.LoggedinToken,
    };
    // console.log(displayData);
    this.props.changeDisplayMode(displayData);
  };

  success = () => {
    message.success("Display mode Updated Successfully..", 5);
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect to="/login-page" />;
    }

    if (
      /* Check if data is avilable */
      !this.props.studentGenfees ||
      !this.props.studentGenfeesHead ||
      !this.props.studentSpecfees ||
      !this.props.studentSpecfeesHead
    ) {
      /* If no data display spinner */
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return <Spin indicator={antIcon} />;
    }
    if (this.props.isUnauthorised) {
      this.props.resetToken();
      this.props.resetStatus();
      this.props.history.push("/login-page");
      window.location.reload();
    }

    if (this.props.displayUpdateSuccess) {
      this.props.resetDisplayStatus();
      this.props.onReset();
      this.props.onGetStGenDataOps(this.state.LoggedinToken);
      this.props.onGetStSpecDataOps(this.state.LoggedinToken);
      this.success();
    }

    // console.log(this.props.isUnauthorised)
    /* Sort data based on keys in studentGenfeesHead[0] */
    let Generalaccessors = Object.keys(this.props.studentGenfeesHead[0]).sort();
    /* Remove name from array index and add in index 0 */

    // console.log(this.props.studentGenfees);

    const Generalindex = Generalaccessors.indexOf("name");
    if (Generalindex > -1) {
      Generalaccessors.splice(Generalindex, 1);
      Generalaccessors = ["name", ...Generalaccessors, "show", "hide"];
    }
    /* Get headings */
    const Generalheaders = Generalaccessors.map(
      (item) => this.props.studentGenfeesHead[0][item] || ""
    );
    // console.log(Generalheaders, Generalaccessors);
    let StudentGenHeadItems = Generalheaders.map((heading, idx) => {
      return {
        Header: heading,
        /* Get accessor based on header Idx */
        accessor: Generalaccessors[idx],
        /* Make cell link */
        Cell: ({ value, row, column }) => {
          const shouldDisplay = row._original.display === "show";
          if (column.id === "name") {
            // console.log(row._original.display);
            return shouldDisplay ? (
              <Link
                style={{ color: shouldDisplay ? "blue" : "black" }}
                to={{
                  pathname: `/fees/edit/${row["_original"].fee_type_idx}`,
                  search: `?host=${1}&category=${1}`,
                  // state: { host: "1", category: "1" },
                }}
                key={`cell-${row.index}-${column.id}`}
              >
                {value}{" "}
              </Link>
            ) : (
              <div>{value} </div>
            );
          } else {
            if (column.id === "show") {
              return (
                <div className="actions-right">
                  <Button
                    bsStyle="danger"
                    onClick={() => {
                      // console.log(row._original.fee_type_idx)
                      let id = row._original.fee_type_idx;
                      this.showHandler(id);
                    }}
                  >
                    SHOW
                  </Button>
                </div>
              );
            }
            if (column.id === "hide") {
              return (
                <div className="actions-right">
                  <Button
                    bsStyle="danger"
                    onClick={() => {
                      let id = row._original.fee_type_idx;
                      this.hideHandler(id);
                    }}
                  >
                    HIDE
                  </Button>
                </div>
              );
            }
            return <div style={{ textAlign: "right" }}>{value}</div>;
          }
        },
      };
    });

    let Specifiedaccessors = Object.keys(
      this.props.studentSpecfeesHead[0]
    ).sort();
    const Specifiedindex = Specifiedaccessors.indexOf("name");
    if (Specifiedindex > -1) {
      Specifiedaccessors.splice(Specifiedindex, 1);
      Specifiedaccessors = ["name", ...Specifiedaccessors, "show", "hide"];
    }
    const Specifiedheaders = Specifiedaccessors.map(
      (item) => this.props.studentSpecfeesHead[0][item]
    );
    // console.log(Specifiedheaders, Specifiedaccessors);
    let StudentSpecHeadItems = Specifiedheaders.map((heading, idx) => {
      // console.log(idx);
      return {
        Header: heading,
        accessor: Specifiedaccessors[idx],
        Cell: ({ value, row, column }) => {
          const shouldDisplayfacility = row._original.display === "show";
          // console.log(row);
          if (column.id === "name") {
            return shouldDisplayfacility ? (
              <Link
                style={{ color: shouldDisplayfacility ? "blue" : "black" }}
                to={{
                  pathname: `/fees/edit/${row["_original"].fee_type_idx}`,
                  search: `?host=${1}&category=${2}`,
                  // state: { host: "1", category: "2" },
                }}
                key={`cell-${row.index}-${column.id}`}
              >
                {value}{" "}
              </Link>
            ) : (
              <div>{value} </div>
            );
          } else {
            if (column.id === "show") {
              return (
                <div className="actions-right">
                  <Button
                    bsStyle="danger"
                    onClick={() => {
                      // console.log(row._original.fee_type_idx)
                      let id = row._original.fee_type_idx;
                      this.showHandler(id);
                    }}
                  >
                    SHOW
                  </Button>
                </div>
              );
            }
            if (column.id === "hide") {
              return (
                <div className="actions-right">
                  <Button
                    bsStyle="danger"
                    onClick={() => {
                      let id = row._original.fee_type_idx;
                      this.hideHandler(id);
                    }}
                  >
                    HIDE
                  </Button>
                </div>
              );
            }
            return <div style={{ textAlign: "right" }}>{value}</div>;
          }
          // return value;
        },
      };
    });

    const filterCaseInsensitive = ({ id, value }, row) =>
      row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    return (
      <Switch>
        <Route path="/fees/addfees" exact component={Addfee} />
        <Route path="/fees/edit/:fee_type_idx" exact component={EditFee} />
        <Route path="/fees/">
          <div>
            {/* Button for switch table */}
            <ButtonGroup
              size="sm"
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: "10px",
              }}
            >
              <Button
                name="button"
                bsStyle={"primary"}
                onClick={this.buttonOne}
              >
                Student-General
              </Button>
              <Button
                name="button"
                bsStyle={"primary"}
                onClick={this.buttonTwo}
              >
                Student-Specified
              </Button>
            </ButtonGroup>
            {/* Check tbValue for display table */}
            {this.state.tbValue === 1 ? (
              <div className="main-content">
                <Grid fluid>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col sm={8}>
                          <h3 style={{ color: "#577360" }}>Student-General</h3>
                        </Col>
                        <Col sm={4}>
                          <Button
                            className="pull-right dark"
                            style={{
                              marginBottom: "20px",
                              marginRight: "10px",
                            }}
                            onClick={this.addButtonHandler}
                          >
                            ADD FEE
                          </Button>
                        </Col>
                      </Row>
                      <Card
                        content={
                          <ReactTable
                            data={this.props.studentGenfees}
                            filterable
                            defaultFilterMethod={filterCaseInsensitive}
                            columns={StudentGenHeadItems}
                            defaultPageSize={10}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                          />
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
              </div>
            ) : this.state.tbValue === 2 ? (
              <div className="main-content">
                <Grid fluid>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col sm={8}>
                          <h3 style={{ color: "#577360" }}>
                            Student-Specified
                          </h3>
                        </Col>
                        <Col sm={4}>
                          <Button
                            className="pull-right dark"
                            style={{
                              marginBottom: "20px",
                              marginRight: "10px",
                            }}
                            onClick={this.addButtonHandler}
                          >
                            ADD FEE
                          </Button>
                        </Col>
                      </Row>
                      <Card
                        content={
                          <ReactTable
                            data={this.props.studentSpecfees}
                            filterable
                            defaultFilterMethod={filterCaseInsensitive}
                            columns={StudentSpecHeadItems}
                            defaultPageSize={10}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                          />
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
              </div>
            ) : null}
          </div>
        </Route>
      </Switch>
    );
  }
}

/* Get data from redux state */
const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    studentGenfees: state.getFeeOps.studentGenFees,
    studentGenfeesHead: state.getFeeOps.studentGenFeesHead,
    studentSpecfees: state.getFeeOps.studentSpecFees,
    studentSpecfeesHead: state.getFeeOps.studentSpecFeesHead,
    isUnauthorised:
      (state.getFeeOps.feeStatus ||
        state.getFeeOps.studentGenStatus ||
        state.getFeeOps.studentSpecStatus) === 401,
    // feeStatus: state.getFeeOps.feeStatus,
    // studentGenStatus: state.getFeeOps.studentGenStatus,
    // studentSpecStatus: state.getFeeOps.studentSpecStatus,
    // facilityfees: state.getFeeOps.facilityFees,
    // facilityfeesHead: state.getFeeOps.facilityFeesHead,
    displayUpdateSuccess: state.getFeeOps.displayStatus === "success",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetStGenDataOps: (LoggedinToken) => {
      dispatch(actions.getDataOps(LoggedinToken));
      dispatch(actions.getFeeOps(LoggedinToken));
    },
    onGetStSpecDataOps: (LoggedinToken) =>
      dispatch(actions.getDataSpecOps(LoggedinToken)),
    // onGetFacilityDataOps: (LoggedinToken) =>
    //   dispatch(actions.getDataFacilityOps(LoggedinToken)),
    onReset: () => dispatch(actions.resetFeelist()),
    resetToken: () => dispatch(actions.resetToken()),
    resetStatus: () => dispatch(actions.resetStatus()),
    changeDisplayMode: (displayData) =>
      dispatch(actions.onDisplayModeChange(displayData)),
    resetDisplayStatus: () => dispatch(actions.resetDisplayUpdateStatus()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReactTables));
