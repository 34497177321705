import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import "antd/dist/antd.css";
import Button from "components/CustomButton/CustomButton.jsx";
import { Redirect } from "react-router";
import Card from "components/Card/Card.jsx";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../assets/img/back.png";
import errorIcon from "../../assets/img/error.png";

import { connect } from "react-redux";
import * as actions from "../../Store/Actions/Index";
import moment from "moment";

class Addfee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      Fee: "",
      selectCategory: "1",
      selectHost: "2",
      startDate: new Date(),
      endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 60)),
      fees_amounts: [{ fee_payment_idx: "4", amount: "500" }],
      edit: false,
      LoggedinToken: this.props.logintoken,
      gotValues: false,
      Name: "",
      backClicked: false,
      validationError: "",
      changed: false,
    };
  }
  componentWillMount() {
    // console.log("Edit fee mounted..");
    const urlParams = new URLSearchParams(this.props.location.search);
    const hostData = urlParams.get("host");
    const category = urlParams.get("category");
    if (hostData) {
      this.setState({ selectHost: hostData }, () => {
        // console.log(this.state.selectHost);
      });
    }
    if (category) {
      this.setState({ selectCategory: category }, () => {
        // console.log(this.state.selectCategory);
      });
    }
    // console.log(this.props.match.params);
    const Name = this.props.match.params.name;
    this.setState({ Name: this.props.match.params.name }, () => {
    });
    // let accessor = [];

    let theData = this.props.facilityfees;
    // console.log("state:: ", this.state);
    if (hostData === "" || category === "") {
      return;
    }

    // if (hostData === "2" && category === "1") {
    //   // facilityfees
    //   theData = this.props.facilityfees;
    //   accessor = Object.keys(this.props.facilityfeesHead[0]).filter(
    //     (key) => key !== "name"
    //   );
    // }
    const Data = theData.filter((data) => data.name === Name);
    // console.log("data: ", Data);

    if (!Data.length === 1) {
      return;
    }
    const fees = Data[0];
    // console.log("the fees:: ", fees);
    this.setState({
      gotValues: true,
      Fee: fees.name,
    });
  }

  componentWillUnmount = () => {
    this.props.onGetFacilityDataOps(this.state.LoggedinToken);
    this.props.onReset();
  };

  componentWillUnmount() {
    this.props.onReset();
  }

  Feehandlechange(event) {
    this.setState({
      changed: true,
      Fee: event.target.value,
    });
  }

  submitButton(event) {
    event.preventDefault();
    let Data = {};
    if (this.state.Fee !== "" && this.state.Fee !== 0) {
      Data.fee = this.state.Fee;
    }
    if (this.state.selectCategory !== "" && this.state.selectCategory !== 0) {
      Data.category = this.state.selectCategory;
    }
    if (this.state.selectHost !== "" && this.state.selectHost !== 0) {
      Data.host = this.state.selectHost;
    }
    if (this.state.startDate !== "" && this.state.startDate !== 0) {
      Data.start_date = moment(this.state.startDate).format("YYYY-MM-DD");
    }
    if (this.state.endDate !== "" && this.state.endDate !== 0) {
      Data.end_date = moment(this.state.endDate).format("YYYY-MM-DD");
    }
    if (this.state.fees_amounts !== "" && this.state.fees_amounts !== 0) {
      Data.fees_amounts = this.state.fees_amounts.filter(
        (fee_amount) => !(fee_amount.amount === "0" || fee_amount.amount === "")
      );
    }
    if (this.state.LoggedinToken !== "" && this.state.LoggedinToken !== 0) {
      Data.token = this.state.LoggedinToken;
    }
    if (!this.state.changed) {
      this.setState({ validationError: "Make change to submit" });
    } else if (this.state.Fee === "") {
      this.setState({ validationError: "Feename is required" });
    } else {
      this.props.onEdit(Data);
    }
  }

  editButtonHandler(event) {
    event.preventDefault();
    // console.log("Edit Button Clicked");
    this.setState((prevState) => ({ edit: !prevState.edit }));
  }

  deleteButtonHandler(event) {
    event.preventDefault();
    // console.log("Delete Button Clicked");
    const data = {
      feeIdx: this.state.feeTypeIdx,
      token: this.state.LoggedinToken,
    };
    this.props.onDelete(data);
  }

  backButtonHandler(event) {
    event.preventDefault();
    this.setState({ backClicked: true });
  }

  success = () => {
    message.success("Edit Successfull..", 5);
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect to="/login-page" />;
    }
    if (!this.props.facilityfees) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

      return <Spin indicator={antIcon} />;
    }
    if (this.props.isUnauthorised) {
      this.props.resetToken()
      this.props.resetStatus()
      this.props.history.push("/login-page")
      window.location.reload()
    }
    if (this.props.added) {
      this.props.onResetFeelist();
      this.success();
        this.props.history.push("/facilityList/facilityFeeList")
    }
    if (this.state.backClicked) {
      return <Redirect to="/facilityList/facilityFeeList" />;
    }

    return (
      <Grid fluid style={{ minHeight: "80%" }}>
        <Row style={{ marginTop: "20px" }}>
          <Col sm={12} mt={3}>
            <Button
              style={{ marginBottom: "5px" }}
              onClick={(event) => this.backButtonHandler(event)}
            >
              <img src={backIcon} alt="bc" />
              BACK
            </Button>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                // title="Edit/Delete Feetype"
                content={
                  <div>
                    <h4
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                        marginBottom: "20px",
                        color: "#577360",
                      }}
                    >
                      Edit/Delete Facility-Hire
                    </h4>
                    <Row>
                      <FormGroup>
                        <Col sm={2}>
                          <ControlLabel>Facility Name:</ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <FormControl
                            placeholder="Enter facility name"
                            value={this.state.Fee}
                            type="text"
                            name="feetype"
                            disabled={!this.state.edit}
                            onChange={(event) => this.Feehandlechange(event)}
                          />
                        </Col>
                      </FormGroup>
                    </Row>
                    {this.state.validationError !== "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        <img
                          src={errorIcon}
                          alt="err"
                          style={{ maxHeight: "30px", maxWidth: "30px" }}
                        />
                        {this.state.validationError}
                      </span>
                    ) : null}
                  </div>
                }
                legend={
                  <div>
                    <Button
                      bsStyle="info"
                      fill
                      wd
                      onClick={(event) => this.editButtonHandler(event)}
                    >
                      Edit
                    </Button>
                    <Button
                      bsStyle="info"
                      fill
                      wd
                      style={{ marginLeft: "5px" }}
                      onClick={(event) => this.deleteButtonHandler(event)}
                    >
                      Delete
                    </Button>
                    {this.state.edit ? (
                      <div>
                        <Button
                          bsStyle="info"
                          fill
                          wd
                          style={{ marginTop: "5px" }}
                          onClick={(event) => this.submitButton(event)}
                        >
                          Submit
                        </Button>
                      </div>
                    ) : null}
                  </div>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: !!state.auth.token,
    added: state.addfee.Status === 200,
    facilityfees: state.getFeeOps.facilityFees,
    facilityfeesHead: state.getFeeOps.facilityFeesHead,
    isUnauthorised: state.getFeeOps.facilityStatus === 401
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFacilityDataOps: (LoggedinToken) => {
      dispatch(actions.getDataFacilityOps(LoggedinToken));
      dispatch(actions.getFeeOps(LoggedinToken));
    },
    onEdit: (Data) => dispatch(actions.editFee(Data)),
    onDelete: (data) => dispatch(actions.deleteFee(data)),
    onReset: () => dispatch(actions.statReset()),
    onResetFeelist: () => dispatch(actions.resetFeelist()),
    resetToken: () => dispatch(actions.resetToken()),
    resetStatus: () => dispatch(actions.resetStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Addfee));
