import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import {
  Select,
  Spin,
  message,
  // Alert
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import "antd/dist/antd.css";
import Button from "components/CustomButton/CustomButton.jsx";
import { Redirect } from "react-router";
import Card from "components/Card/Card.jsx";
import "react-datepicker/dist/react-datepicker.css";
import errorIcon from "../../assets/img/error.png";
import backIcon from "../../assets/img/back.png";

import { connect } from "react-redux";
import * as actions from "../../Store/Actions/Index";
import OfferPaymentType from "./OfferPaymentType";

const { Option } = Select;

class Offers extends Component {
  state = {
    claimCategory: "generic_fee_amounts",
    claimFee: "",
    amountClaimmable: "",
    deductCategory: "generic_fee_amounts",
    deductClass: "",
    deductFee: "",
    claimText: "",
    offerPayData: {},
    fees_amounts: [],
    LoggedinToken: this.props.logintoken,
    validationError: "",
  };

  // componentDidMount = () => {
  //   this.props.onGetStGenDataOps(this.state.LoggedinToken);
  //   this.props.onGetStSpecDataOps(this.state.LoggedinToken);
  // };

  componentWillUnmount = () => {
    this.props.onGetOfferData(this.state.LoggedinToken);
  };

  paymentTypeChange = () => {
    if (this.state.deductFee !== "") {
      // let paymentData = [];
      const parsed = parseInt(this.state.deductFee);
      let filtered = [];
      if (this.state.deductCategory === "generic_fee_amounts") {
        filtered = this.props.studentGenfees.filter(
          (data) => data.fee_type_idx === parsed
        );
      } else if (this.state.deductCategory === "class_specific_fee_amounts") {
        filtered = this.props.studentSpecfees.filter(
          (data) => data.fee_type_idx === parsed
        );
      } else return filtered;
      const newData = {};
      if (filtered.length > 0) {
        Object.entries(filtered[0])
          .filter(([, k]) => k !== "")
          .forEach(([key, value]) => (newData[key] = value));
        const keyd = Object.keys(newData).map((dtdt) => parseInt(dtdt));
        const andata = keyd.filter((nandata) => isNaN(nandata) === false);
        const paymentData = andata.map((mapdata) =>
          this.props.PaymentTypesData.filter(
            (type) => type.fee_payment_idx === mapdata
          )
        );
        return paymentData;
      }
    }
  };

  payOnchange = (offerPayData) => {
    this.setState({
      offerPayData,
    });
    this.setState({
      fees_amounts: Object.keys(offerPayData).map((aKey) => {
        return {
          fee_payment_idx: aKey,
          amount: offerPayData[aKey].amount,
        };
      }),
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
    let addData = {};
    if (this.state.claimCategory !== "" && this.state.claimCategory !== 0) {
      addData.claimCategory = this.state.claimCategory;
    }
    if (this.state.claimFee !== "" && this.state.claimFee !== 0) {
      addData.claimFee = this.state.claimFee;
    }
    if (this.state.deductCategory !== "" && this.state.deductCategory !== 0) {
      addData.deductCategory = this.state.deductCategory;
    }
    if (
      this.state.amountClaimmable !== "" &&
      this.state.amountClaimmable !== 0
    ) {
      addData.amountClaimmable = this.state.amountClaimmable;
    }
    if (this.state.claimText !== "" && this.state.claimText !== 0) {
      addData.claimText = this.state.claimText;
    }
    // if (this.state.deductClass !== "" && this.state.deductClass !== 0) {
    addData.deductClass = this.state.deductClass;
    // }
    if (this.state.fees_amounts !== "" && this.state.fees_amounts !== 0) {
      addData.fees_amounts = this.state.fees_amounts;
    }
    if (this.state.LoggedinToken !== "" && this.state.LoggedinToken !== 0) {
      addData.LoggedinToken = this.state.LoggedinToken;
    }
    if (
      this.state.claimFee === "" ||
      this.state.deductFee === "" ||
      this.state.amountClaimmable === ""
    ) {
      this.setState({
        validationError: "Claim Fee, Deduct Fee and claim amount is required",
      });
    } else {
      this.props.onAddOffer(addData);
    }
  };

  backButtonHandler(event) {
    event.preventDefault();
    this.props.history.push("/offerList/offerList");
  }

  success = () => {
    message.success("Offer Added Successfully..", 5);
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect push to="/login-page" />;
    }
    if (
      !this.props.studentGenfees ||
      !this.props.studentSpecfees ||
      !this.props.PaymentTypesData
    ) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return <Spin indicator={antIcon} />;
    }
    if (this.props.isUnauthorised) {
      this.props.resetToken();
      this.props.resetStatus();
      this.props.history.push("/login-page");
      window.location.reload();
    }

    if (this.props.successStatus) {
      this.props.onResetStatus();
      this.success();
      this.props.history.push("/offerList/offerList");
    }
    return (
      <Grid fluid>
        <Row style={{ marginTop: "20px" }}>
          <Col sm={12}>
            <Button
              style={{ marginBottom: "5px" }}
              onClick={(event) => this.backButtonHandler(event)}
            >
              <img src={backIcon} alt="bc" />
              BACK
            </Button>
            <form>
              <Card
                textCenter
                content={
                  <div>
                    <div>
                      <h4
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                          color: "#577360",
                        }}
                      >
                        Add Offers
                      </h4>
                    </div>
                    <div>
                      <Row style={{ marginTop: "2px" }}>
                        <FormGroup>
                          <Col sm={2} style={{ marginTop: "5px" }}>
                            <ControlLabel>Claim Fee Category:</ControlLabel>
                          </Col>
                          <Col sm={10}>
                            <Select
                              size="large"
                              style={{ width: "100%" }}
                              defaultValue="generic_fee_amounts"
                              onChange={(value) =>
                                this.setState({ claimCategory: `${value}` })
                              }
                            >
                              <Option value="generic_fee_amounts">
                                General
                              </Option>
                            </Select>
                          </Col>
                        </FormGroup>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <FormGroup>
                          <Col sm={2} style={{ marginTop: "5px" }}>
                            <ControlLabel>Select Fee:</ControlLabel>
                          </Col>
                          <Col sm={10}>
                            <Select
                              size="large"
                              style={{ width: "100%" }}
                              defaultValue="Select Fee"
                              onChange={(value) => {
                                const results = value.split("|");
                                this.setState({
                                  claimFee: results[0],
                                  claimClass: results[1],
                                });
                              }}
                            >
                              {this.state.claimCategory ===
                              "generic_fee_amounts"
                                ? this.props.studentGenfees.map(
                                    (filteredGenFees) => (
                                      <Option
                                        key={filteredGenFees.fee_type_idx}
                                        value={`${
                                          filteredGenFees.fee_type_idx
                                        }|${""}`}
                                      >
                                        {filteredGenFees.name}
                                      </Option>
                                    )
                                  )
                                : null}
                            </Select>
                          </Col>
                        </FormGroup>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <FormGroup>
                          <Col sm={2} style={{ marginTop: "5px" }}>
                            <ControlLabel>Offer Amount:</ControlLabel>
                          </Col>
                          <Col sm={10}>
                            <FormControl
                              placeholder="Amount Claimmable"
                              value={this.state.amountClaimmable}
                              type="number"
                              onChange={(event) =>
                                this.setState({
                                  amountClaimmable: event.target.value,
                                })
                              }
                            />
                          </Col>
                        </FormGroup>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <FormGroup>
                          <Col sm={2} style={{ marginTop: "5px" }}>
                            <ControlLabel>Deduct Fee Category:</ControlLabel>
                          </Col>
                          <Col sm={10}>
                            <Select
                              size="large"
                              style={{ width: "100%" }}
                              defaultValue="generic_fee_amounts"
                              onChange={(value) => {
                                this.setState({ deductCategory: `${value}` });
                              }}
                            >
                              <Option value="generic_fee_amounts">
                                General
                              </Option>
                              <Option value="class_specific_fee_amounts">
                                Specified
                              </Option>
                            </Select>
                          </Col>
                        </FormGroup>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <FormGroup>
                          <Col sm={2} style={{ marginTop: "5px" }}>
                            <ControlLabel>Select Fee:</ControlLabel>
                          </Col>
                          <Col sm={10}>
                            <Select
                              size="large"
                              style={{ width: "100%" }}
                              defaultValue="Select Fee"
                              onChange={(value) => {
                                const result = value.split("|");
                                this.setState({
                                  deductFee: result[0],
                                  deductClass: result[1],
                                });
                              }}
                            >
                              {this.state.deductCategory ===
                              "generic_fee_amounts"
                                ? this.props.studentGenfees.map((Fees) => (
                                    <Option
                                      key={Fees.fee_type_idx}
                                      value={`${Fees.fee_type_idx}|${""}`}
                                    >
                                      {Fees.name}
                                    </Option>
                                  ))
                                : this.state.deductCategory ===
                                  "class_specific_fee_amounts"
                                ? this.props.studentSpecfees.map((Fees) => (
                                    <Option
                                      key={Fees.fee_type_idx}
                                      value={`${Fees.fee_type_idx}|${Fees.class_idx}`}
                                    >
                                      {Fees.name}
                                    </Option>
                                  ))
                                : null}
                            </Select>
                          </Col>
                        </FormGroup>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <FormGroup>
                          <Col sm={2} style={{ marginTop: "5px" }}>
                            <ControlLabel>Claim Text:</ControlLabel>
                          </Col>
                          <Col sm={10}>
                            <FormControl
                              placeholder="Claim Text"
                              value={this.state.claimText}
                              type="text"
                              onChange={(event) =>
                                this.setState({
                                  claimText: event.target.value,
                                })
                              }
                            />
                          </Col>
                        </FormGroup>
                      </Row>
                      {this.state.deductFee !== "" ? (
                        <OfferPaymentType
                          paymentData={this.paymentTypeChange()}
                          data={this.state.offerPayData}
                          onChange={this.payOnchange}
                        />
                      ) : null}
                      {this.state.validationError !== "" ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          <img
                            src={errorIcon}
                            alt="err"
                            style={{ maxHeight: "30px", maxWidth: "30px" }}
                          />
                          {this.state.validationError}
                        </span>
                      ) : null}
                    </div>
                  </div>
                }
                legend={
                  <Button
                    bsStyle="info"
                    fill
                    wd
                    onClick={(event) => this.submitHandler(event)}
                  >
                    Submit
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    studentGenfees: state.getFeeOps.studentGenFees,
    studentSpecfees: state.getFeeOps.studentSpecFees,
    ClassData: state.getFeeOps.classesData,
    PaymentTypesData: state.getFeeOps.PaymentTypeData,
    successStatus: state.offer.status === 200,
    isUnauthorised:
      (state.getFeeOps.feeStatus ||
        state.getFeeOps.studentGenStatus ||
        state.getFeeOps.studentSpecStatus) === 401,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddOffer: (addData) => dispatch(actions.addOffer(addData)),
    onGetOfferData: (LoggedinToken) =>
      dispatch(actions.getOffer(LoggedinToken)),
    onResetStatus: () => dispatch(actions.resetOfferStatus()),
    resetToken: () => dispatch(actions.resetToken()),
    resetStatus: () => dispatch(actions.resetStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Offers));
