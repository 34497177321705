import { updatedObject } from "../Utility";

const initialState = {
  Fee: "",
  selectHost: "",
  selectCategory: "",
  selectClass: "",
  selectPayment: "",
  amount: "",
  startDate: "",
  endDate: "",
  error: null,
  Status: "",
};

const addStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const addSuccess = (state, action) => {
  return updatedObject(state, {
    Status: action.status,
    error: null,
  });
};

const statReset = (state, action) => {
  return updatedObject(state,{
    Status: null,
  })
}

const addFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const editStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const editSuccess = (state, action) => {
  return updatedObject(state, {
    Status: action.status,
    error: null,
  });
};

const editFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const deleteStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const deleteSuccess = (state, action) => {
  return updatedObject(state, {
    Status: action.status,
    error: null,
  });
};

const deleteFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_START":
      return addStart(state, action);
    case "ADD_SUCCESS":
      return addSuccess(state, action);
    case "STAT_RESET":
      return statReset(state, action);
    case "ADD_FAIL":
      return addFail(state, action);
    case "EDIT_START":
      return editStart(state, action);
    case "EDIT_SUCCESS":
      return editSuccess(state, action);
    case "EDIT_FAIL":
      return editFail(state, action);
    case "DELETE_START":
      return deleteStart(state, action);
    case "DELETE_SUCCESS":
      return deleteSuccess(state, action);
    case "DELETE_FAIL":
      return deleteFail(state, action);
    default:
      return state;
  }
};

export default reducer;
