import LoginPage from "views/Pages/LoginPage.jsx";
import Addfees from "views/Pages/AddFees";
import FeesList from "views/Pages/FeesList";
import Logout from "components/Logout/Logout";
import EditFees from "views/Pages/EditFees";
import Report from "views/Pages/Report";
import IndividualReport from "views/Pages/IndividualReport";
import DatewiseReport from "views/Pages/DatewiseReport";
import Refund from "views/Pages/Refund";
import FacilityFeesList from "views/Pages/FacilityFeesList";
import AddFacilityHire from "views/Pages/AddFacilityHire";
import EditFacilityHire from "views/Pages/EditFacilityHire";
import Offers from "views/Pages/Offers";
import OfferList from "views/Pages/OfferList";
import AcademicYear from "views/Pages/AcademicYear";
import DatewiseIndividualReport from 'views/Pages/DatewiseIndividualReport';

const getRoutesForUser = (userType = "admin") => {
  const commonRoutes = [
    {
      path: "/logout",
      layout: "/logout",
      name: "Logout",
      component: Logout,
    },
    {
      path: "/login-page",
      layout: "/auth",
      component: LoginPage,
    },
  ];
  const directorRoutes = [
    {
      path: "/report",
      layout: "/List",
      name: "Report",
      component: Report,
    },
    {
      path: "/datewiseReport",
      layout: "/List",
      name: "Datewise Report",
      component: DatewiseReport,
    },
    {
      path: "/logout",
      layout: "/logout",
      name: "Logout",
      component: Logout,
    },
    {
      path: "/login-page",
      layout: "/auth",
      component: LoginPage,
    },
  ];
  const adminRoutes = [
    {
      path: "/",
      layout: "/fees",
      name: "Student",
      component: FeesList,
    },
    {
      path: "/facilityFeeList",
      layout: "/facilityList",
      name: "Facility",
      component: FacilityFeesList,
    },
    {
      path: "/editfees/:fee_type_idx",
      layout: "/fees",
      component: EditFees,
    },
    {
      path: "/report",
      layout: "/List",
      name: "Report",
      component: Report,
    },
    {
      path: "/datewiseReport",
      layout: "/List",
      name: "Datewise Report",
      component: DatewiseReport,
    },
    {
      path: "/refund",
      layout: "/refund",
      name: "Refund",
      component: Refund,
    },
    {
      path: "/offerList",
      layout: "/offerList",
      name: "Offers",
      component: OfferList,
    },
    {
      path: "/addOffers",
      layout: "/offerList/offerList",
      // name: "Add Offers",
      component: Offers,
    },
    {
      path: "/individualReport:Qid",
      layout: "/List/report",
      component: IndividualReport,
    },
    {
      path: "/datewiseIndividualReport:Qid",
      layout: "/List/datewiseReport",
      component: DatewiseIndividualReport,
    },
    {
      path: "/academicYear",
      layout: "/academicYear",
      name: "Academic Year",
      component: AcademicYear,
    },
    {
      path: "/logout",
      layout: "/logout",
      name: "Logout",
      component: Logout,
    },
    {
      path: "/addfees",
      layout: "/fees",
      component: Addfees,
    },
    {
      path: "/addFacilityFees",
      layout: "/facilityList/facilityFeeList",
      component: AddFacilityHire,
    },
    {
      path: "/editFacilityFees/:name",
      layout: "/facilityList/facilityFeeList",
      component: EditFacilityHire,
    },
    {
      path: "/login-page",
      layout: "/auth",
      component: LoginPage,
    },
  ];

  if (userType === "administrator") {
    return [...adminRoutes];
  }
  if (userType === "reportuser") {
    return [...directorRoutes];
  }
  return commonRoutes;
};

export default getRoutesForUser;
