import { updatedObject } from "../Utility";

const initialState = {
  token: null,
  User: null,
  error: null,
};

const authStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const authSuccess = (state, action) => {
  return updatedObject(state, {
    token: action.token,
    User: action.user,
    error: null,
  });
};

const authFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const authLogoutStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const authLogoutSuccess = (state, action) => {
  // console.log(action);
  return updatedObject(state, {
    token: null,
    Status: action.status,
  });
};

const authLogoutFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const resetToken = (state, action) => {
  return updatedObject(state, {
    token: "",
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "AUTH_START":
      return authStart(state, action);
    case "AUTH_SUCCESS":
      return authSuccess(state, action);
    case "AUTH_FAIL":
      return authFail(state, action);
    case "AUTH_LOGOUT_START":
      return authLogoutStart(state, action);
    case "AUTH_LOGOUT_SUCCESS":
      return authLogoutSuccess(state, action);
    case "AUTH_LOGOUT_FAIL":
      return authLogoutFail(state, action);
    case "RESET_TOKEN":
      return resetToken(state, action);
    default:
      return state;
  }
};

export default reducer;
