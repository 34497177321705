import Axios from "../../Axios";

export const refundStart = () => {
  return {
    type: "REFUND_START",
  };
};

export const refundSuccess = (status) => {
  return {
    type: "REFUND_SUCCESS",
    Status: status,
  };
};

export const refundFail = (error) => {
  return {
    type: "REFUND_FAIL",
    error: error,
  };
};

export const getRefund = (data) => {
  // console.log(data.LoggedinToken);
  const Data = {
    pun: data.pun,
    total_amount: data.amount,
  };
  return (dispatch) => {
    dispatch(refundStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${data.LoggedinToken}`,
    };
    Axios.post("/refund", Data, {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.status);
        // console.log(response.data);
        dispatch(refundSuccess(response.status));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(refundFail(error));
      });
  };
};
