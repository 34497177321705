import { updatedObject } from "../Utility";

const initialState = {
  error: null,
  PaymentTypeData: "",
  classesData: "",
  studentGenFees: "",
  studentGenFeesHead: "",
  studentSpecFees: "",
  studentSpecFeesHead: "",
  facilityFees: "",
  facilityFeesHead: "",
  studentCustomerData: "",
  facilityCustomerData: "",
  feeStatus: "",
  studentGenStatus: "",
  studentSpecStatus: "",
  facilityStatus: "",
  studentReportStatus: "",
  facilityReportStatus: "",
  displayStatus: "",
  studentDateFiltered: "",
  facilityDateFiltered: "",
};

const getStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const getSuccess = (state, action) => {
  return updatedObject(state, {
    PaymentTypeData: action.PaymentTypesData,
    classesData: action.ClassData,
    feeStatus: action.status,
    error: null,
  });
};

const getFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const getStudentGenStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const getStudentGenSuccess = (state, action) => {
  // console.log(action);
  return updatedObject(state, {
    studentGenFees: [...action.stuGenFees],
    studentGenFeesHead: [...action.stuGenFeesHead],
    studentGenStatus: action.status,
    error: null,
  });
};

const getStudentGenFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const getStudentSpecStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const getStudentSpecSuccess = (state, action) => {
  // console.log(action);
  return updatedObject(state, {
    studentSpecFees: [...action.stuSpecFees],
    studentSpecFeesHead: [...action.stuSpecFeesHead],
    studentSpecStatus: action.status,
    error: null,
  });
};

const getStudentSpecFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const getFacilityStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const getFacilitySuccess = (state, action) => {
  // console.log(action);
  return updatedObject(state, {
    facilityFees: [...action.facilityFees],
    facilityFeesHead: [...action.facilityFeesHead],
    facilityStatus: action.status,
    error: null,
  });
};

const getFacilityFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const getStudentPaidStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const getStudentPaidSuccess = (state, action) => {
  // console.log(action);
  return updatedObject(state, {
    error: null,
    studentCustomerData: action.studentCustomer,
    studentReportStatus: action.status,
  });
};

const getStudentPaidFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const getFacilityPaidStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const getFacilityPaidSuccess = (state, action) => {
  // console.log(action);
  return updatedObject(state, {
    error: null,
    facilityCustomerData: action.facilityCustomer,
    facilityReportStatus: action.status,
  });
};

const getFacilityPaidFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const resetFeelist = (state, action) => {
  return updatedObject(state, {
    studentGenFees: "",
    studentSpecFees: "",
    facilityFees: "",
  });
};

const resetStatus = (state, action) => {
  return updatedObject(state, {
    feeStatus: "",
    studentGenStatus: "",
    studentSpecStatus: "",
    facilityStatus: "",
    studentReportStatus: "",
    facilityReportStatus: "",
  });
};

const displayModeChangeSuccess = (state, action) => {
  // console.log(action);
  return updatedObject(state, {
    error: null,
    displayStatus: action.status,
  });
};

const displayModeChangeFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const resetDisplayUpdateStatus = (state, action) => {
  return updatedObject(state, {
    displayStatus: "",
  });
};

const getStudentReportFromDataSuccess = (state, action) => {
  return updatedObject(state, {
    error: null,
    studentDateFiltered: action.dateFiltered,
  });
};

const getStudentReportFromDataFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const getFacilityReportFromDataSuccess = (state, action) => {
  return updatedObject(state, {
    error: null,
    facilityDateFiltered: action.dateFiltered,
  });
};

const getFacilityReportFromDataFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_START":
      return getStart(state, action);
    case "GET_SUCCESS":
      return getSuccess(state, action);
    case "GET_FAIL":
      return getFail(state, action);
    case "GET_STUDENTGEN_START":
      return getStudentGenStart(state, action);
    case "GET_STUDENTGEN_SUCCESS":
      return getStudentGenSuccess(state, action);
    case "GET_STUDENTGEN_FAIL":
      return getStudentGenFail(state, action);
    case "GET_STUDENTSSPEC_START":
      return getStudentSpecStart(state, action);
    case "GET_STUDENTSPEC_SUCCESS":
      return getStudentSpecSuccess(state, action);
    case "GET_STUDENTSSPEC_FAIL":
      return getStudentSpecFail(state, action);
    case "GET_FACILITY_START":
      return getFacilityStart(state, action);
    case "GET_FACILITY_SUCCESS":
      return getFacilitySuccess(state, action);
    case "GET_FACILITY_FAIL":
      return getFacilityFail(state, action);
    case "GET_STUDENT_PAID_START":
      return getStudentPaidStart(state, action);
    case "GET_STUDENT_PAID_SUCCESS":
      return getStudentPaidSuccess(state, action);
    case "GET_STUDENT_PAID_FAIL":
      return getStudentPaidFail(state, action);
    case "GET_FACILITY_PAID_START":
      return getFacilityPaidStart(state, action);
    case "GET_FACILITY_PAID_SUCCESS":
      return getFacilityPaidSuccess(state, action);
    case "GET_FACILITY_PAID_FAIL":
      return getFacilityPaidFail(state, action);
    case "RESET_FEELIST":
      return resetFeelist(state, action);
    case "RESET_STATUS":
      return resetStatus(state, action);
    case "DISPLAY_MODE_CHANGE_SUCCESS":
      return displayModeChangeSuccess(state, action);
    case "DISPLAY_MODE_CHANGE_FAIL":
      return displayModeChangeFail(state, action);
    case "RESET_DISPLAY_UPDATE_STATUS":
      return resetDisplayUpdateStatus(state, action);
    case "GET_STUDENT_REPORT_FROM_DATA_SUCCESS":
      return getStudentReportFromDataSuccess(state, action);
    case "GET_STUDENT_REPORT_FROM_DATA_FAIL":
      return getStudentReportFromDataFail(state, action);
      case "GET_FACILITY_REPORT_FROM_DATA_SUCCESS":
      return getFacilityReportFromDataSuccess(state, action);
    case "GET_FACILITY_REPORT_FROM_DATA_FAIL":
      return getFacilityReportFromDataFail(state, action);
    default:
      return state;
  }
};

export default reducer;
