// let Global = {
//   apiUrl: "https://pay.durhamqatar.com",
//   siteUrl: "https://admin01.durhamqatar.com",
// };

// if (process.env.NODE_ENV === "development") {
//   Global.apiUrl = "https://pay.durhamqatar.com";
//   Global.siteUrl = "https://pay.durhamqatar.com";
// }

// let Global = {
//     apiUrl: "https://payment.durhamqatar.com",
//     siteUrl: "http://localhost:3000",
//   };
  
//   if (process.env.NODE_ENV === "development") {
//     Global.apiUrl = "https://payment.durhamqatar.com";
//     Global.siteUrl = "https://payment.durhamqatar.com";
//   }

let Global = {
    apiUrl: "https://payment.durhamqatar.com",
    siteUrl: "https://payment.durhamqatar.com",
};
  
if (process.env.NODE_ENV === "development") {
    Global.apiUrl = "http://localhost:8000";
    Global.siteUrl = "http://localhost:8000";
}

export default Global;
