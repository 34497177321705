import { updatedObject } from "../Utility";

const initialState = {
  error: null,
  OfferData: "",
  Status: "",
  status: "",
  offerStatus: "",
};

const addOfferStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const addOfferSuccess = (state, action) => {
  return updatedObject(state, {
    error: null,
    status: action.status,
  });
};

const resetOfferStatus = (state, action) => {
  return updatedObject(state, {
    status: "",
  });
};

const addOfferFail = (state, action) => {
  return updatedObject(state, {
    error: "",
  });
};

const getOfferStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const getOfferSuccess = (state, action) => {
  return updatedObject(state, {
    error: null,
    OfferData: action.listData,
    offerStatus: action.status,
  });
};

const getOfferFail = (state, action) => {
  return updatedObject(state, {
    error: "",
  });
};

const resetOfferData = (state, action) => {
  return updatedObject(state, {
    Status: "",
  });
};

const deleteOfferStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const deleteOfferSuccess = (state, action) => {
  // console.log(action);
  return updatedObject(state, {
    Status: action.status,
  });
};

const deleteOfferFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const resetStatusData = (state, action) => {
  return updatedObject(state, {
    offerStatus: "",
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_OFFER_START":
      return addOfferStart(state, action);
    case "ADD_OFFER_SUCCESS":
      return addOfferSuccess(state, action);
    case "ADD_OFFER_FAIL":
      return addOfferFail(state, action);
    case "GET_OFFER_START":
      return getOfferStart(state, action);
    case "GET_OFFER_SUCCESS":
      return getOfferSuccess(state, action);
    case "GET_OFFER_FAIL":
      return getOfferFail(state, action);
    case "DELETE_OFFER_START":
      return deleteOfferStart(state, action);
    case "DELETE_OFFER_SUCCESS":
      return deleteOfferSuccess(state, action);
    case "DELETE_OFFER_FAIL":
      return deleteOfferFail(state, action);
    case "RESET_OFFER_DATA":
      return resetOfferData(state, action);
    case "RESET_OFFER_STATUS":
      return resetOfferStatus(state, action);
    case "RESET_STATUS":
      return resetStatusData(state, action);
    default:
      return state;
  }
};

export default reducer;
