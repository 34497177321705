import Axios from "../../Axios";

export const authStart = () => {
  return {
    type: "AUTH_START",
  };
};

export const authSuccess = (token, username) => {
  return {
    type: "AUTH_SUCCESS",
    token: token,
    user: username,
  };
};

export const authFail = (error) => {
  return {
    type: "AUTH_FAIL",
    error: error,
  };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const AuthData = {
      username: email,
      password: password,
    };
    Axios.post("/login/", AuthData)
      .then((response) => {
        // console.log(response.data);
        // const expirationDate = new Date(new Date().getTime() * 1000);
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("username", response.data.data.username);
        // localStorage.setItem("expirationDate", expirationDate);
        dispatch(
          authSuccess(response.data.data.token, response.data.data.username)
        );
        // dispatch(logout);
      })
      .catch((error) => {
        // console.log(error);
        dispatch(authFail(error));
      });
  };
};

export const authLogoutStart = () => {
  return {
    type: "AUTH_LOGOUT_START",
  };
};

export const authLogoutSuccess = (stat) => {
  localStorage.removeItem("token");
  return {
    type: "AUTH_LOGOUT_SUCCESS",
    status: stat,
  };
};

export const authLogoutFail = (error) => {
  return {
    type: "AUTH_LOGOUT_FAIL",
    error: error,
  };
};

// export const checkAuthTimeout = (expirationTime) => {
//   return (dispatch) => {
//     setTimeout(() => {
//       // dispatch(logout());
//     }, expirationTime * 1000);
//   };
// };

export const authLogout = (LoggedinToken) => {
  return (dispatch) => {
    dispatch(authLogoutStart());
    // console.log(LoggedinToken);
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${LoggedinToken}`,
    };
    Axios.get("/logout/", {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.data);
        // console.log(response.status);
        dispatch(authLogoutSuccess(response.status));
        // dispatch(logout);
      })
      .catch((error) => {
        // console.log(error);
        dispatch(authLogoutFail(error));
      });
  };
};

export const resetToken = () => {
  localStorage.removeItem("token");
  return {
    type: "RESET_TOKEN",
  };
};
