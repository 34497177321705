/*eslint-disable*/
import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer
        className={
          "footer" +
          (this.props.transparent !== undefined ? " footer-transparent" : "")
        }
      >
        <div
          className={
            "container" + (this.props.fluid !== undefined ? "-fluid" : "")
          }
        >
          <nav className="pull-left">
            <ul>
              <li>
                <a href="">Durham School Doha</a>
              </li>
            </ul>
          </nav>
            <p className="copyright pull-right">
              &copy; {1900 + new Date().getYear()}{" "}
              <a href="" target="_blank"></a>, Made with{" "}
              <i className="fa fa-heart heart" /> for a better web
            </p>
        </div>
      </footer>
    );
  }
}
export default Footer;
