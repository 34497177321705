import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import AuthReducer from "./Store/Reducers/Auth";
import AddReducer from "./Store/Reducers/Addfee";
import FeeOpsReducer from "./Store/Reducers/Feetypes";
import RefundReducer from "./Store/Reducers/Refund";
import OfferReducer from "./Store/Reducers/Offers";
import yearReducer from "./Store/Reducers/AcademicYear";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";

import FeesList from "./layouts/FeeList";
import Logout from "components/Logout/Logout";
import Reports from "./layouts/Reports";
import DatewiseReport from "./layouts/DatewiseReport";
import Refunds from "./layouts/Refunds.jsx";
import FacilityFeelist from "./layouts/FacilityFeelist";
import OfferList from "./layouts/OfferList";
import AcademicYear from "./layouts/AcademicYear";

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const rootReducer = combineReducers({
  auth: AuthReducer,
  addfee: AddReducer,
  getFeeOps: FeeOpsReducer,
  getRefund: RefundReducer,
  offer: OfferReducer,
  academicYear: yearReducer,
});

const store = createStore(
  rootReducer,
  {
    auth: {
      token: localStorage.getItem("token", null),
      User: localStorage.getItem("username", null),
    },
  },
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/offerList/offerList" component={OfferList} />
        <Route path="/List/report" component={Reports} />
        <Route path="/List/datewiseReport" component={DatewiseReport} />
        <Route path="/fees" component={FeesList} />
        <Route path="/academicYear" component={AcademicYear} />
        <Route
          path="/facilityList/facilityFeeList"
          component={FacilityFeelist}
        />
        <Route path="/refund" component={Refunds} />
        <Route path="/logout/logout" component={Logout} />
        <Redirect from="/" to="/auth/login-page" />
      </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);
