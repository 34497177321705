import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";

import "antd/dist/antd.css";
// import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Button from "components/CustomButton/CustomButton.jsx";
import { Redirect } from "react-router";
import Card from "components/Card/Card.jsx";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../assets/img/back.png";
import errorIcon from "../../assets/img/error.png";

import { connect } from "react-redux";
import * as actions from "../../Store/Actions/Index";
import moment from "moment";
import { message } from "antd";

class AddFacilityFee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      Fee: "",
      selectCategory: "1",
      selectHost: "2",
      startDate: new Date(),
      endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 60)),
      fees_amounts: [{ fee_payment_idx: "4", amount: "500" }],
      LoggedinToken: this.props.logintoken,
      backClicked: false,
      Status: "",
      validationError: "",
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const hostData = urlParams.get("host");
    const category = urlParams.get("category");
    if (hostData) {
      this.setState({ selectHost: hostData }, () => {});
    }
    if (category) {
      this.setState({ selectCategory: category }, () => {});
    }
  }
  componentWillUnmount = () => {
    this.props.onGetFacilityDataOps(this.state.LoggedinToken);
    this.props.onReset();
  };

  Feehandlechange(event) {
    this.setState({
      Fee: event.target.value,
    });
  }

  backButtonHandler(event) {
    event.preventDefault();
    this.setState({ backClicked: true });
  }

  submitButton(event) {
    let Data = {};
    if (this.state.Fee !== "" && this.state.Fee !== 0) {
      Data.fee = this.state.Fee;
    }
    if (this.state.selectCategory !== "" && this.state.selectCategory !== 0) {
      Data.category = this.state.selectCategory;
    }
    if (this.state.selectHost !== "" && this.state.selectHost !== 0) {
      Data.host = this.state.selectHost;
    }
    if (this.state.startDate !== "" && this.state.startDate !== 0) {
      Data.start_date = moment(this.state.startDate).format("YYYY-MM-DD");
    }
    if (this.state.endDate !== "" && this.state.endDate !== 0) {
      Data.end_date = moment(this.state.endDate).format("YYYY-MM-DD");
    }
    if (this.state.fees_amounts !== "" && this.state.fees_amounts !== 0) {
      Data.fees_amounts = this.state.fees_amounts.filter(
        (fee_amount) => !(fee_amount.amount === "0" || fee_amount.amount === "")
      );
    }
    if (this.state.LoggedinToken !== "" && this.state.LoggedinToken !== 0) {
      Data.token = this.state.LoggedinToken;
    }
    if (this.state.Fee === "") {
      this.setState({ validationError: "Feename is required" });
    } else {
      this.props.onAdd(Data);
    }
  }

  success = () => {
    message.success("Facility Fee Added Successfully..", 5);
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect push to="/login-page" />;
    }
    if (this.props.added) {
      this.props.onResetFeelist();
      this.success();
      this.props.history.push("/facilityList/facilityFeeList");
    }
    if (this.state.backClicked) {
      return <Redirect push to="/facilityList/facilityFeeList" />;
    }
    return (
      <Grid fluid style={{ minHeight: "80%" }}>
        <Row style={{ marginTop: "20px" }}>
          <Col sm={12} mt={3}>
            <Button
              style={{ marginBottom: "5px" }}
              onClick={(event) => this.backButtonHandler(event)}
            >
              <img src={backIcon} alt="bc" />
              BACK
            </Button>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                content={
                  <div>
                    <h4
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                        marginBottom: "20px",
                        color: "#577360",
                      }}
                    >
                      Add Facility-Hire
                    </h4>
                    <FormGroup>
                      <Row>
                        <Col sm={2}>
                          <ControlLabel>Facility Name:</ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <FormControl
                            placeholder="Enter facility name"
                            value={this.state.Fee}
                            type="text"
                            name="feetype"
                            onChange={(event) => this.Feehandlechange(event)}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    {this.state.validationError !== "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        <img
                          src={errorIcon}
                          alt="err"
                          style={{ maxHeight: "30px", maxWidth: "30px" }}
                        />
                        {this.state.validationError}
                      </span>
                    ) : null}
                  </div>
                }
                legend={
                  <Button
                    bsStyle="info"
                    fill
                    wd
                    onClick={(event) => this.submitButton(event)}
                  >
                    Submit
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: !!state.auth.token,
    // ClassData: state.getFeeOps.classesData,
    // PaymentTypesData: state.getFeeOps.PaymentTypeData,
    added: state.addfee.Status === 200,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFacilityDataOps: (LoggedinToken) => {
      dispatch(actions.getDataFacilityOps(LoggedinToken));
      dispatch(actions.getFeeOps(LoggedinToken));
    },
    onAdd: (Data) => dispatch(actions.addFee(Data)),
    onReset: () => dispatch(actions.statReset()),
    onResetFeelist: () => dispatch(actions.resetFeelist()),
    // onGet: (LoggedinToken) => dispatch(actions.getFeeOps(LoggedinToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddFacilityFee));
