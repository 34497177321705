import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import "antd/dist/antd.css";
import Button from "components/CustomButton/CustomButton.jsx";
import { Redirect } from "react-router";
import Card from "components/Card/Card.jsx";
import "react-datepicker/dist/react-datepicker.css";
import errorIcon from "../../assets/img/error.png";

import { connect } from "react-redux";
import * as actions from "../../Store/Actions/Index";

class AcademicYear extends Component {
  state = {
    academicYear: "",
    LoggedinToken: this.props.logintoken,
    validationError: "",
  };

  componentDidMount = () => {
    this.props.onGetAcademicYear(this.state.LoggedinToken);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.setState({ academicYear: "" });
    }
  };

  yearChangeHandler = (event) => {
    event.preventDefault();
    this.setState({ academicYear: event.target.value });
  };

  submitButtonHandler = (event) => {
    event.preventDefault();
    let updateData = {};
    if (this.props.YearData) {
      updateData.aid = this.props.YearData.aid;
    }
    if (this.state.academicYear !== "" || this.state.academicYear !== 0) {
      updateData.academicYear = this.state.academicYear;
    }
    if (this.state.LoggedinToken !== "" || this.state.LoggedinToken !== 0) {
      updateData.LoggedinToken = this.state.LoggedinToken;
    }
    if (this.state.academicYear === "" || !this.props.YearData.aid) {
      this.setState({ validationError: "Enter Valid Data" });
    } else {
      this.props.onUpdateYear(updateData);
    }
  };

  success = () => {
    message.success("Year Updated Successfully..", 5);
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect push to="/login-page" />;
    }
    if (!this.props.YearData) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return <Spin indicator={antIcon} />;
    }
    if (this.props.isUnauthorised) {
      this.props.resetToken();
      this.props.resetStatus();
      this.props.history.push("/login-page");
      window.location.reload();
    }
    if (this.props.Updated) {
      this.props.resetUpdateStatus();
      this.props.resetData();
      this.props.onGetAcademicYear(this.state.LoggedinToken);
      this.success();
    }
    return (
      <Grid fluid style={{ minHeight: "80%" }}>
        <Row style={{ marginTop: "20px" }}>
          <Col sm={12} mt={3}>
            <Card
              textCenter
              content={
                <div>
                  <h4
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                      color: "#577360",
                    }}
                  >
                    Academic Year
                  </h4>
                  <h5 style={{ marginTop: "20px", marginBottom: "20px" }}>
                    AID: {this.props.YearData.aid}
                  </h5>
                  <h5 style={{ marginTop: "20px", marginBottom: "20px" }}>
                    Academic year: {this.props.YearData.year}
                  </h5>
                  <FormGroup>
                    <ControlLabel>Update Academic Year</ControlLabel>
                    <FormControl
                      value={this.state.academicYear}
                      placeholder="eg: 2020-2021"
                      type="text"
                      name="academicYear"
                      onChange={this.yearChangeHandler}
                    />
                  </FormGroup>
                  {this.state.validationError !== "" ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      <img
                        src={errorIcon}
                        alt="err"
                        style={{ maxHeight: "30px", maxWidth: "30px" }}
                      />
                      {this.state.validationError}
                    </span>
                  ) : null}
                </div>
              }
              legend={
                <Button
                  bsStyle="info"
                  fill
                  wd
                  onClick={(event) => this.submitButtonHandler(event)}
                >
                  Update
                </Button>
              }
              ftTextCenter
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: !!state.auth.token,
    YearData: state.academicYear.academicYear,
    isUnauthorised: state.academicYear.getStatus === 401,
    Updated: state.academicYear.updateStatus === 200,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAcademicYear: (LoggedinToken) => {
      dispatch(actions.getAcademicYear(LoggedinToken));
    },
    onUpdateYear: (updateData) => {
      dispatch(actions.updateAcademicYear(updateData));
    },
    resetToken: () => dispatch(actions.resetToken()),
    resetStatus: () => dispatch(actions.resetGetStatus()),
    resetUpdateStatus: () => dispatch(actions.resetUpdateStatus()),
    resetData: () => dispatch(actions.resetData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AcademicYear));
