import React, { Component } from "react";
import ReactTable from "react-table";
import { Grid, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as actions from "../../Store/Actions/Index";
import Card from "components/Card/Card.jsx";
import { withRouter, Link, Route, Switch, Redirect } from "react-router-dom";
import AddFacilityHire from "./AddFacilityHire";
import EditFacilityHire from "./EditFacilityHire";
import "../../assets/css/Feelist.module.css";

class FacilityFeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoggedinToken: this.props.logintoken,
      add: false,
      show: "1",
    };
  }

  componentDidMount = () => {
    /* Pass logintoken to fetch data */
    this.props.onGetFacilityDataOps(this.state.LoggedinToken);
  };

  componentWillUnmount = () => {
    this.props.onReset();
  };

  /* Button Handler to switch tables */

  addButtonHandler = () => {
    /* Redirect with corresponding data */
    this.props.history.push({
      pathname: "/facilityList/facilityFeeList/addFacilityFees",
      search: `?host=${2}&category=${1}`,
    });
  };

  myFacilityCell = ({ value, row, column }) => {
    // console.log(row._original.display);
    const shouldDisplay = row._original.display === "show";
    return shouldDisplay ? (
      <Link
        style={{ color: shouldDisplay ? "blue" : "black" }}
        to={{
          pathname: `/facilityList/facilityFeeList/editFacilityFees/${row.name}`,
          search: `?host=${2}&category=${1}`,
        }}
        key={`cell-${row.index}-${column.id}`}
      >
        {value}{" "}
      </Link>
    ) : (
      <div>{value} </div>
    );
  };

  showHandler = (id) => {
    const displayData = {
      display: "show",
      fee_type_idx: id,
      LoggedinToken: this.state.LoggedinToken,
    };
    // console.log(displayData);
    this.props.changeDisplayMode(displayData);
  };

  hideHandler = (id) => {
    const displayData = {
      display: "hidden",
      fee_type_idx: id,
      LoggedinToken: this.state.LoggedinToken,
    };
    // console.log(displayData);
    this.props.changeDisplayMode(displayData);
  };

  success = () => {
    message.success("Display mode Updated Successfully..", 5);
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect to="/login-page" />;
    }
    if (
      /* Check if data is avilable */
      !this.props.facilityfees ||
      !this.props.facilityfeesHead
    ) {
      /* If no data display spinner */
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return <Spin indicator={antIcon} />;
    }
    if (this.props.isUnauthorised) {
      this.props.resetToken();
      this.props.resetStatus();
      this.props.history.push("/login-page");
      window.location.reload();
    }

    if (this.props.displayUpdateSuccess) {
      this.props.resetDisplayStatus();
      this.props.onReset();
      this.props.onGetFacilityDataOps(this.state.LoggedinToken);
      this.success();
    }

    // console.log(this.props.facilityfees);
    // console.log(this.props.displayUpdateSuccess);

    let nameAccessor = [];
    nameAccessor = this.props.facilityfees.map((Item, key) => {
      return {
        fee_type_idx: Item.fee_type_idx,
        id: key + 1,
        name: Item.name,
        display: Item.display,
      };
    });

    // console.log(nameAccessor);

    const filterCaseInsensitive = ({ id, value }, row) =>
      row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    return (
      <Switch>
        <Route
          path="/facilityList/facilityFeeList/addFacilityFees"
          exact
          component={AddFacilityHire}
        />
        <Route
          path="/facilityList/facilityFeeList/editFacilityFees/:name"
          exact
          component={EditFacilityHire}
        />
        <Route path="/facilityList/facilityFeeList">
          <div className="main-content">
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col sm={8}>
                      <h3 style={{ color: "#577360" }}>Facility-Hire</h3>
                    </Col>
                    <Col sm={4}>
                      <Button
                        className="pull-right dark"
                        style={{
                          marginBottom: "20px",
                          marginRight: "10px",
                        }}
                        onClick={this.addButtonHandler}
                      >
                        ADD FACILITY-HIRE
                      </Button>
                    </Col>
                  </Row>
                  <Card
                    content={
                      <ReactTable
                        data={nameAccessor}
                        filterable
                        defaultFilterMethod={filterCaseInsensitive}
                        columns={[
                          {
                            width: 50,
                            Header: <div>#</div>,
                            accessor: "id",
                          },
                          {
                            Header: (
                              <div style={{ textAlign: "left" }}>Name</div>
                            ),
                            accessor: "name",
                            Cell: this.myFacilityCell,
                          },
                          {
                            width: 100,
                            Cell: (row) => (
                              <div className="actions-right">
                                <Button
                                  bsStyle="danger"
                                  onClick={() => {
                                    let id =
                                      nameAccessor[row.index].fee_type_idx;
                                    this.showHandler(id);
                                  }}
                                >
                                  SHOW
                                </Button>
                              </div>
                            ),
                          },
                          {
                            width: 100,
                            Cell: (row) => (
                              <div className="actions-right">
                                <Button
                                  bsStyle="danger"
                                  onClick={() => {
                                    let CDID =
                                      nameAccessor[row.index].fee_type_idx;
                                    this.hideHandler(CDID);
                                  }}
                                >
                                  HIDE
                                </Button>
                              </div>
                            ),
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </Route>
      </Switch>
    );
  }
}

/* Get data from redux state */
const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    facilityfees: state.getFeeOps.facilityFees,
    facilityfeesHead: state.getFeeOps.facilityFeesHead,
    isUnauthorised: state.getFeeOps.facilityStatus === 401,
    displayUpdateSuccess: state.getFeeOps.displayStatus === "success",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFacilityDataOps: (LoggedinToken) => {
      dispatch(actions.getDataFacilityOps(LoggedinToken));
      dispatch(actions.getFeeOps(LoggedinToken));
    },
    onReset: () => dispatch(actions.resetFeelist()),
    resetToken: () => dispatch(actions.resetToken()),
    resetStatus: () => dispatch(actions.resetStatus()),
    changeDisplayMode: (displayData) =>
      dispatch(actions.onDisplayModeChange(displayData)),
    resetDisplayStatus: () => dispatch(actions.resetDisplayUpdateStatus()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FacilityFeeList));
