import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Checkbox,
} from "react-bootstrap";
import {
  Select,
  Spin,
   message
  // Alert, Popconfirm
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import "antd/dist/antd.css";
// import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Button from "components/CustomButton/CustomButton.jsx";
import { Redirect } from "react-router";
import Card from "components/Card/Card.jsx";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../assets/img/back.png";
import errorIcon from "../../assets/img/error.png";
import Datetime from "react-datetime";

import { connect } from "react-redux";
import * as actions from "../../Store/Actions/Index";
import moment from "moment";
import FeeGroup from "./FeeGroup";

const { Option } = Select;

class Addfee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      Fee: "",
      selectCategory: "1",
      selectHost: "",
      startDate: new Date(),
      endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 60)),
      selectClass: "",
      feeGroupAmounts: {}, // {[key]: { amount: 200}, }
      fees_amounts: [],
      LoggedinToken: this.props.logintoken,
      backClicked: false,
      Status: "",
      validationError: "",
      is_activity: false,
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const hostData = urlParams.get("host");
    const category = urlParams.get("category");
    if (hostData) {
      this.setState({ selectHost: hostData }, () => {
      });
    }
    if (category) {
      this.setState({ selectCategory: category }, () => {
      });
    }
  }
  componentWillUnmount = () => {
    this.props.onGetStGenDataOps(this.state.LoggedinToken);
    this.props.onGetStSpecDataOps(this.state.LoggedinToken);
    this.props.onGetFacilityDataOps(this.state.LoggedinToken);
    this.props.onReset();
  };

  Feehandlechange(event) {
    this.setState({
      Fee: event.target.value,
    });
  }

  SelectClassChange = (value) => {
    this.setState({ selectClass: `${value}` }, () => {
    });
  };

  onChange = (feeGroupAmounts) => {
    this.setState({
      feeGroupAmounts,
    });
    this.setState({
      fees_amounts: Object.keys(feeGroupAmounts).map((aKey) => {
        return {
          fee_payment_idx: aKey,
          class_idx: this.state.selectClass,
          amount: feeGroupAmounts[aKey].amount,
        };
      }),
    });
  };

  handleCheckboxChange(event) {
    this.setState({is_activity: !this.state.is_activity});
  }

  backButtonHandler(event) {
    event.preventDefault();
    this.setState({ backClicked: true });
  }

  submitButton(event) {
    let Data = {};
    if (this.state.Fee !== "" && this.state.Fee !== 0) {
      Data.fee = this.state.Fee;
    }
    if (this.state.selectCategory !== "" && this.state.selectCategory !== 0) {
      Data.category = this.state.selectCategory;
    }
    if (this.state.selectHost !== "" && this.state.selectHost !== 0) {
      Data.host = this.state.selectHost;
    }
    if (this.state.startDate !== "" && this.state.startDate !== 0) {
      Data.start_date = moment(this.state.startDate).format("YYYY-MM-DD");
    }
    if (this.state.endDate !== "" && this.state.endDate !== 0) {
      Data.end_date = moment(this.state.endDate).format("YYYY-MM-DD");
    }
    if (this.state.fees_amounts !== "" && this.state.fees_amounts !== 0) {
      Data.fees_amounts = this.state.fees_amounts.filter(
        (fee_amount) => !(fee_amount.amount === "0" || fee_amount.amount === "")
      );
    }
    if (this.state.LoggedinToken !== "" && this.state.LoggedinToken !== 0) {
      Data.token = this.state.LoggedinToken;
    }
    Data.is_activity = this.state.is_activity;

    if (this.state.Fee === "" || this.state.fees_amounts.length < 1) {
      this.setState({ validationError: "Feename and amount is required" });
    } else {
      this.props.onAdd(Data);
    }
  }

  startDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  endDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  success = () => {
    message.success("Student Fee Added Successfully..", 5);
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect push to="/login-page" />;
    }
    if (!this.props.PaymentTypesData) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return <Spin indicator={antIcon} />;
    }
    if (this.props.isUnauthorised) {
      this.props.resetToken()
      this.props.resetStatus()
      this.props.history.push("/login-page")
      window.location.reload()
    }
    if (this.props.added) {
      this.props.onResetFeelist();
      this.success();
      // alert("Fee Added Successfully!!!", 
      this.props.history.push("/fees/")
      // );
    }
    if (this.state.backClicked) {
      return <Redirect push to="/fees/" />;
    }
    return (
      <Grid fluid style={{ minHeight: "80%" }}>
        <Row style={{ marginTop: "20px" }}>
          <Col sm={12} mt={3}>
            <Button
              style={{ marginBottom: "5px" }}
              onClick={(event) => this.backButtonHandler(event)}
            >
              <img src={backIcon} alt="bc" />
              BACK
            </Button>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                content={
                  <div>
                    {this.state.selectHost === "1" &&
                    this.state.selectCategory === "1" ? (
                      <h4
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                          color: "#577360",
                        }}
                      >
                        Add Student-General Fee
                      </h4>
                    ) : null}
                    {this.state.selectHost === "1" &&
                    this.state.selectCategory === "2" ? (
                      <h4
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                          color: "#577360",
                        }}
                      >
                        Add Student-Specified Fee
                      </h4>
                    ) : null}
                    {this.state.selectHost === "2" &&
                    this.state.selectCategory === "1" ? (
                      <h4
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                          color: "#577360",
                        }}
                      >
                        Add Facility-Hire Fee
                      </h4>
                    ) : null}
                    <FormGroup>
                      <Row>
                        <Col sm={2}>
                          <ControlLabel>Fee Name:</ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <FormControl
                            placeholder="Enter fee name"
                            value={this.state.Fee}
                            type="text"
                            name="feetype"
                            onChange={(event) => this.Feehandlechange(event)}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    {this.state.selectCategory === "2" ? (
                      <FormGroup>
                        <Row>
                          <Col sm={2}>
                            <ControlLabel>Select Class</ControlLabel>
                          </Col>
                          <Col sm={10}>
                            <Select
                              defaultValue="Select class"
                              style={{ width: "100%" }}
                              onChange={this.SelectClassChange}
                            >
                              {this.props.ClassData.map((classes) => (
                                <Option
                                  key={classes.class_idx}
                                  value={classes.class_idx}
                                >
                                  {classes.name}
                                </Option>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                      </FormGroup>
                    ) : null}

                    <FeeGroup
                      data={this.state.feeGroupAmounts} // [{id:this.props.key, amount: 200}]
                      onChange={this.onChange}
                    />
                    <Row>
                      <FormGroup>
                        <Col sm={2}>
                          <ControlLabel>Start Date: </ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <Datetime
                            timeFormat={false}
                            dateFormat="DD-MM-YYYY"
                            value={this.state.startDate}
                            selected={this.state.startDate}
                            onChange={this.startDateChange}
                          />
                        </Col>
                      </FormGroup>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                      <FormGroup>
                        <Col sm={2}>
                          <ControlLabel>End Date: </ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <Datetime
                            timeFormat={false}
                            dateFormat="DD-MM-YYYY"
                            value={this.state.endDate}
                            selected={this.state.endDate}
                            onChange={this.endDateChange}
                          />
                        </Col>
                      </FormGroup>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                      <FormGroup>
                        <Col sm={2}>
                          <ControlLabel>Is Activity Fee?: </ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <input
                            name="is_activity"
                            type="checkbox"
                            checked={this.state.is_activity}
                            onChange={this.handleCheckboxChange} />
                        </Col>
                      </FormGroup>
                    </Row>
                    {this.state.validationError !== "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        <img
                          src={errorIcon}
                          alt="err"
                          style={{ maxHeight: "30px", maxWidth: "30px" }}
                        />
                        {this.state.validationError}
                      </span>
                    ) : null}
                  </div>
                }
                legend={
                  <Button
                    bsStyle="info"
                    fill
                    wd
                    onClick={(event) => this.submitButton(event)}
                  >
                    Submit
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: !!state.auth.token,
    ClassData: state.getFeeOps.classesData,
    PaymentTypesData: state.getFeeOps.PaymentTypeData,
    added: state.addfee.Status === 200,
    isUnauthorised: state.getFeeOps.feeStatus === 401
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetStGenDataOps: (LoggedinToken) => {
      dispatch(actions.getDataOps(LoggedinToken));
      dispatch(actions.getFeeOps(LoggedinToken));
    },
    onGetStSpecDataOps: (LoggedinToken) =>
      dispatch(actions.getDataSpecOps(LoggedinToken)),
    onGetFacilityDataOps: (LoggedinToken) =>
      dispatch(actions.getDataFacilityOps(LoggedinToken)),
    onAdd: (Data) => dispatch(actions.addFee(Data)),
    onReset: () => dispatch(actions.statReset()),
    onResetFeelist: () => dispatch(actions.resetFeelist()),
    resetToken: () => dispatch(actions.resetToken()),
    resetStatus: () => dispatch(actions.resetStatus()),
    // onGet: (LoggedinToken) => dispatch(actions.getFeeOps(LoggedinToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Addfee));
