import Axios from "../../Axios";

export const getStart = () => {
  return {
    type: "GET_START",
  };
};

export const getSuccess = (classData, paymentTypesData, status) => {
  return {
    type: "GET_SUCCESS",
    ClassData: classData,
    PaymentTypesData: paymentTypesData,
    status: status,
  };
};

export const getFail = (error) => {
  return {
    type: "GET_FAIL",
    error: error,
  };
};

export const getFeeOps = (LoggedinToken) => {
  return (dispatch) => {
    dispatch(getStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${LoggedinToken}`,
    };
    Axios.get("/class-fee_types/list", {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.status);
        // console.log(response.data.data);
        dispatch(
          getSuccess(
            response.data.data.class,
            response.data.data.fee_payments,
            response.status
          )
        );
      })
      .catch((error) => {
        // console.log(error);
        dispatch(getFail(error));
      });
  };
};

export const getStudentGenStart = () => {
  return {
    type: "GET_STUDENTGEN_START",
  };
};

export const getStudentGenSuccess = (tbody, thead, status) => {
  // console.log(fees_types);
  return {
    type: "GET_STUDENTGEN_SUCCESS",
    stuGenFees: tbody,
    stuGenFeesHead: thead,
    status: status,
  };
};

export const resetFeelist = () => {
  return {
    type: "RESET_FEELIST",
  };
};

export const getStudentGenFail = (error) => {
  return {
    type: "GET_STUDENTGEN_FAIL",
    error: error,
  };
};

export const getDataOps = (LoggedinToken) => {
  return (dispatch) => {
    dispatch(getStudentGenStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${LoggedinToken}`,
    };
    Axios.get("/fees_lists_table/list/1", {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.status);
        // console.log(response.data);
        dispatch(
          getStudentGenSuccess(
            response.data.data.tbody,
            response.data.data.thead,
            response.status
          )
        );
      })
      .catch((error) => {
        // console.log(error);
        dispatch(getStudentGenFail(error));
      });
  };
};

export const getStudentSpecStart = () => {
  return {
    type: "GET_STUDENTSPEC_START",
  };
};

export const getStudentSpecSuccess = (tbody, thead, status) => {
  // console.log(tbody);
  return {
    type: "GET_STUDENTSPEC_SUCCESS",
    stuSpecFees: tbody,
    stuSpecFeesHead: thead,
    status: status,
  };
};

export const getStudentSpecFail = (error) => {
  return {
    type: "GET_STUDENTSPEC_FAIL",
    error: error,
  };
};

export const getDataSpecOps = (LoggedinToken) => {
  return (dispatch) => {
    dispatch(getStudentSpecStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${LoggedinToken}`,
    };
    Axios.get("/fees_lists_table/list/2", {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.status);
        // console.log(response.data);
        dispatch(
          getStudentSpecSuccess(
            response.data.data.tbody,
            response.data.data.thead,
            response.status
          )
        );
      })
      .catch((error) => {
        // console.log(error);
        dispatch(getStudentSpecFail(error));
      });
  };
};

export const getFacilityStart = () => {
  return {
    type: "GET_FACILITY_START",
  };
};

export const getFacilitySuccess = (tbody, thead, status) => {
  // console.log(fees_types);
  return {
    type: "GET_FACILITY_SUCCESS",
    facilityFees: tbody,
    facilityFeesHead: thead,
    status: status,
  };
};

export const getFacilityFail = (error) => {
  return {
    type: "GET_FACILITY_FAIL",
    error: error,
  };
};

export const getDataFacilityOps = (LoggedinToken) => {
  return (dispatch) => {
    dispatch(getFacilityStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${LoggedinToken}`,
    };
    Axios.get("/fees_lists_table/list/3", {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.status);
        // console.log(response.data);
        dispatch(
          getFacilitySuccess(
            response.data.data.tbody,
            response.data.data.thead,
            response.status
          )
        );
      })
      .catch((error) => {
        // console.log(error);
        dispatch(getFacilityFail(error));
      });
  };
};

export const getStudentPaidStart = () => {
  return {
    type: "GET_STUDENT_PAID_START",
  };
};

export const getStudentPaidSuccess = (customer, status) => {
  return {
    type: "GET_STUDENT_PAID_SUCCESS",
    studentCustomer: customer,
    status: status,
  };
};

export const getStudentPaidFail = (error) => {
  return {
    type: "GET_STUDENT_FAIL",
    error: error,
  };
};

export const getStudentFeePaid = (LoggedinToken) => {
  // console.log(LoggedinToken);
  return (dispatch) => {
    dispatch(getStudentPaidStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${LoggedinToken}`,
    };
    Axios.get("/transactionreport/student", {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.status);
        // console.log(response.data);
        dispatch(getStudentPaidSuccess(response.data, response.status));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(getStudentPaidFail(error));
      });
  };
};

export const getFacilityPaidStart = () => {
  return {
    type: "GET_FACILITY_PAID_START",
  };
};

export const getFacilityPaidSuccess = (customer, status) => {
  // console.log(customer);
  return {
    type: "GET_FACILITY_PAID_SUCCESS",
    facilityCustomer: customer,
    status: status,
  };
};

export const getFacilityPaidFail = (error) => {
  return {
    type: "GET_FACILITY_FAIL",
    error: error,
  };
};

export const getFacilityFeePaid = (LoggedinToken) => {
  // console.log(LoggedinToken);
  return (dispatch) => {
    dispatch(getFacilityPaidStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${LoggedinToken}`,
    };
    Axios.get("/transactionreport/facility", {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.status);
        // console.log(response.data);
        dispatch(getFacilityPaidSuccess(response.data, response.status));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(getFacilityPaidFail(error));
      });
  };
};

export const resetStatus = () => {
  return {
    type: "RESET_STATUS",
  };
};

export const displayModeChangeSuccess = (status) => {
  // console.log(customer);
  return {
    type: "DISPLAY_MODE_CHANGE_SUCCESS",
    status: status,
  };
};

export const displayModeChangeFail = (error) => {
  return {
    type: "DISPLAY_MODE_CHANGE_FAIL",
    error: error,
  };
};

export const onDisplayModeChange = (displayData) => {
  // console.log(displayData);
  return (dispatch) => {
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${displayData.LoggedinToken}`,
    };
    Axios.post("/fees_type/display", displayData, {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.status);
        // console.log(response.data);
        dispatch(displayModeChangeSuccess(response.data.status));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(displayModeChangeFail(error));
      });
  };
};

export const resetDisplayUpdateStatus = () => {
  return {
    type: "RESET_DISPLAY_UPDATE_STATUS",
  };
};

export const getStudentReportFromDataSuccess = (data) => {
  return {
    type: "GET_STUDENT_REPORT_FROM_DATA_SUCCESS",
    dateFiltered: data,
  };
};

export const getStudentReportFromDataFail = (error) => {
  return {
    type: "GET_STUDENT_REPORT_FROM_DATA_FAIL",
    error: error,
  };
};

export const getStudentReportFromData = (reportData) => {
  // console.log(reportData);
  return (dispatch) => {
    const dates = reportData;
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${reportData.LoggedinToken}`,
    };
    Axios.post(
      "/payment/datereport/student",
      { from_date: dates.from_date, to_date: dates.to_date },
      {
        headers: Headers,
      }
    )
      .then((response) => {
        // console.log(response.status);
        //console.log(response.data);
        dispatch(getStudentReportFromDataSuccess(response.data));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(getStudentReportFromDataFail(error));
      });
  };
};

export const getFacilityReportFromDataSuccess = (data) => {
  return {
    type: "GET_FACILITY_REPORT_FROM_DATA_SUCCESS",
    dateFiltered: data,
  };
};

export const getFacilityReportFromDataFail = (error) => {
  return {
    type: "GET_FACILITY_REPORT_FROM_DATA_FAIL",
    error: error,
  };
};

export const getFacilityReportFromData = (reportData) => {
  // console.log(reportData);
  return (dispatch) => {
    const dates = reportData;
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${reportData.LoggedinToken}`,
    };
    Axios.post(
      "/payment/datereport/facility",
      { from_date: dates.from_date, to_date: dates.to_date },
      {
        headers: Headers,
      }
    )
      .then((response) => {
        // console.log(response.status);
        // console.log(response.data);
        dispatch(getFacilityReportFromDataSuccess(response.data));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(getFacilityReportFromDataFail(error));
      });
  };
};
