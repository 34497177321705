import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "../../Store/Actions/Index";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";
import { Redirect } from "react-router";
import FeesList from "./FeesList";
import errorIcon from "../../assets/img/error.png";
import Report from "./Report";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      email: "",
      password: "",
      Authenticated: false,
      validationError: "",
    };
  }
  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }

  emailhandlechange(event) {
    this.setState({
      email: event.target.value,
    });
  }
  passwordhandlechange(event) {
    this.setState({
      password: event.target.value,
    });
  }

  submitButton(event) {
    event.preventDefault();
    if (this.state.email && this.state.password) {
      this.props.onAuth(this.state.email, this.state.password);
    } else {
      this.setState({
        validationError: "Please enter a valid username & password",
      });
    }
  }

  render() {
    if (this.props.isAuthenticated && this.props.user === "administrator") {
      return <Redirect to="/fees/" component={FeesList} />;
    }
    if(this.props.isAuthenticated && this.props.user === "reportuser"){
      return <Redirect to="/List/report" component={Report} />
    }
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <Card
              hidden={this.state.cardHidden}
              textCenter
              title="Administrator Login"
              content={
                <div>
                  <form method="post" action="">
                    <FormGroup>
                      <ControlLabel>Username</ControlLabel>
                      <FormControl
                        placeholder="Username"
                        type="email"
                        name="email"
                        onChange={(event) => this.emailhandlechange(event)}
                        required={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        placeholder="Password"
                        type="password"
                        name="password"
                        onChange={(event) => this.passwordhandlechange(event)}
                        autoComplete="off"
                        required={true}
                      />
                    </FormGroup>
                    {this.state.validationError !== "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        <img
                          src={errorIcon}
                          alt="err"
                          style={{ maxHeight: "30px", maxWidth: "30px" }}
                        />
                        {this.state.validationError}
                      </span>
                    ) : null}
                  </form>
                </div>
              }
              legend={
                <Button
                  bsStyle="info"
                  fill
                  wd
                  onClick={(event) => this.submitButton(event)}
                >
                  Login
                </Button>
              }
              ftTextCenter
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    logintoken: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    user: state.auth.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
