import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import Card from "components/Card/Card";
import errorIcon from "../../assets/img/error.png";
import Button from "../../components/CustomButton/CustomButton";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import * as actions from "../../Store/Actions/Index";
import { message } from "antd";

class Refund extends Component {
  state = {
    LoggedinToken: this.props.logintoken,
    pun: "",
    amount: "",
    validationError: "",
  };

  punHandleChange = (event) => {
    event.preventDefault();
    this.setState({ pun: event.target.value });
  };

  amountHandleChange = (event) => {
    event.preventDefault();
    this.setState({ amount: event.target.value });
  };

  submitButtonHandler = (event) => {
    event.preventDefault();
    // console.log("Submitted");
    // console.log(this.state.pun, this.state.amount);
    let data = {
      pun: this.state.pun,
      amount: this.state.amount,
      LoggedinToken: this.state.LoggedinToken,
    };
    if (this.state.pun === "" || this.state.amount === "") {
      this.setState({ validationError: "PUN & Amount is required" });
    } else {
      this.props.onRefund(data);
    }
  };

  success = () => {
    message.success("Refund Successfull..", 5);
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect push to="/login-page" />;
    }
    if (this.props.Success) {
      this.success();
    }
    return (
      <Grid fluid style={{ minHeight: "80%" }}>
        <Row style={{ marginTop: "40px" }}>
          <Col sm={12} mt={3}>
            <form>
              <Card
                textCenter
                content={
                  <div>
                    <h4
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                        marginBottom: "20px",
                        color: "#577360",
                      }}
                    >
                      Refund Amount
                    </h4>
                    <Row>
                      <FormGroup>
                        <Col sm={2}>
                          <ControlLabel>PUN:</ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <FormControl
                            placeholder="Enter PUN"
                            value={this.state.PUN}
                            type="text"
                            name="pun"
                            onChange={(event) => this.punHandleChange(event)}
                          />
                        </Col>
                      </FormGroup>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                      <FormGroup>
                        <Col sm={2}>
                          <ControlLabel>Amount:</ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <FormControl
                            placeholder="Enter Amount"
                            value={this.state.amount}
                            type="number"
                            name="amount"
                            onChange={(event) => this.amountHandleChange(event)}
                          />
                        </Col>
                      </FormGroup>
                    </Row>
                    {this.state.validationError !== "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        <img
                          src={errorIcon}
                          alt="err"
                          style={{ maxHeight: "30px", maxWidth: "30px" }}
                        />
                        {this.state.validationError}
                      </span>
                    ) : null}
                  </div>
                }
                legend={
                  <Button
                    bsStyle="info"
                    fill
                    wd
                    onClick={(event) => this.submitButtonHandler(event)}
                  >
                    Submit
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    Success: state.getRefund.status === 200,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRefund: (data) => dispatch(actions.getRefund(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Refund));
