import React, { Component } from "react";
import {
  Row,
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
} from "react-bootstrap";

import { connect } from "react-redux";
// import * as actions from "../../Store/Actions/Index";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

class OfferPaymentType extends Component {
  // componentDidMount() {
  //   this.props.onGet(this.props.logintoken);
  // }

  onFieldChange = (event, feePaymentIdx) => {
    const fieldValue = event.target.value;
    this.props.onChange({
      ...this.props.data,
      [feePaymentIdx]: { amount: fieldValue },
    });
  };

  render() {
    // console.log(this.props.paymentData);
    if (
      !this.props.PaymentTypesData ||
      !this.props.studentGenfees ||
      !this.props.studentSpecfees ||
      !this.props.paymentData
    ) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return <Spin indicator={antIcon} />;
    }
    if (this.props.paymentData) {
      let Datas = this.props.paymentData;
      for (let i = 0; i < Datas.length; i++) {
        return Datas.map((pyData) =>
          pyData.map((inPyData) => (
            <Row
              key={`payment-${inPyData.fee_payment_idx}`}
              style={{ marginTop: "10px" }}
            >
              <FormGroup>
                <Col sm={2} style={{ marginTop: "5px" }}>
                  <ControlLabel key={inPyData.fee_payment_idx}>
                    {inPyData.name}:
                  </ControlLabel>
                </Col>
                <Col sm={10}>
                  <FormControl
                    key={inPyData.fee_payment_idx}
                    placeholder="Amount"
                    value={
                      this.props.data[inPyData.fee_payment_idx]
                        ? this.props.data[inPyData.fee_payment_idx].amount
                        : ""
                    }
                    type="number"
                    // style={{ width: "100%" }}
                    // disabled={this.props.disabled === false}
                    onChange={(event) => {
                      this.onFieldChange(event, inPyData.fee_payment_idx);
                    }}
                  />
                </Col>
              </FormGroup>
            </Row>
          ))
        );
      }
    } else return "Empty";
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: !!state.auth.token,
    PaymentTypesData: state.getFeeOps.PaymentTypeData,
    studentGenfees: state.getFeeOps.studentGenFees,
    studentSpecfees: state.getFeeOps.studentSpecFees,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     onGet: (LoggedinToken) => dispatch(actions.getFeeOps(LoggedinToken)),
//   };
// };

export default connect(mapStateToProps)(OfferPaymentType);
