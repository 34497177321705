import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { Select, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import "antd/dist/antd.css";
// import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Button from "components/CustomButton/CustomButton.jsx";
import { Redirect } from "react-router";
import Card from "components/Card/Card.jsx";
import Datetime from "react-datetime";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../assets/img/back.png";
import errorIcon from "../../assets/img/error.png";

import { connect } from "react-redux";
import * as actions from "../../Store/Actions/Index";
import moment from "moment";
import FeeGroup from "./FeeGroup";

const { Option } = Select;

class Addfee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      Fee: "",
      selectCategory: "",
      selectHost: "",
      startDate: "",
      endDate: "",
      selectClass: "",
      feeGroupAmounts: {}, // {[key]: { amount: 200}, }
      fees_amounts: [],
      edit: false,
      LoggedinToken: this.props.logintoken,
      gotValues: false,
      feeTypeIdx: "",
      backClicked: false,
      validationError: "",
      changed: false,
      is_activity: "",
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }
  componentWillMount() {
    // console.log("Edit fee mounted..");
    const urlParams = new URLSearchParams(this.props.location.search);
    const hostData = urlParams.get("host");
    const category = urlParams.get("category");
    if (hostData) {
      this.setState({ selectHost: hostData }, () => {
        // console.log(this.state.selectHost);
      });
    }
    if (category) {
      this.setState({ selectCategory: category }, () => {
        // console.log(this.state.selectCategory);
      });
    }
    const feeTypeIdx = parseInt(this.props.match.params.fee_type_idx);
    this.setState({ feeTypeIdx: this.props.match.params.fee_type_idx }, () => {
      // console.log(this.state.feeTypeIdx);
    });
    // NaN
    if (isNaN(feeTypeIdx)) {
      return;
    }
    let accessor = [];

    let theData = this.props.studentGenfees;
    // console.log("state:: ", this.state);
    if (hostData === "" || category === "") {
      return;
    }
    if (hostData === "1" && category === "1") {
      // studentGenfees
      theData = this.props.studentGenfees;
      accessor = Object.keys(this.props.studentGenfeesHead[0]).filter(
        (key) => key !== "name"
      );
    }

    if (hostData === "1" && category === "2") {
      // studentSpecific
      theData = this.props.studentSpecfees;
      accessor = Object.keys(this.props.studentSpecfeesHead[0]).filter(
        (key) => key !== "name"
      );
    }

    if (hostData === "2" && category === "1") {
      // facilityfees
      theData = this.props.facilityfees;
      accessor = Object.keys(this.props.facilityfeesHead[0]).filter(
        (key) => key !== "name"
      );
    }
    
    const Data = theData.filter((data) => data.fee_type_idx === feeTypeIdx);
    //console.log("data: ", Data);

    if (!Data.length === 1) {
      return;
    }
    const fees = Data[0];
    console.log("the fees:: ", fees);

    const feeNameAndAmount = accessor.reduce((acc, key) => {
      return { ...acc, [key]: { amount: fees[key] } };
    }, {});
    let selectClass;
    if (hostData === "1" && category === "2") {
      selectClass = fees.class_idx;
    }
    //console.log("student specific: ", fees.class_idx);
    this.setState({
      gotValues: true,
      feeGroupAmounts: feeNameAndAmount,
      Fee: fees.name,
      startDate: moment(fees.start_date, "YYYY-MM-DD  HH:mm:ss").toDate(),
      endDate: moment(fees.end_date, "YYYY-MM-DD  HH:mm:ss").toDate(),
      selectClass,
      is_activity: fees.is_activity
    });

    // console.log("host:: ", hostData, " category:: ", category);
  }

  componentWillUnmount = () => {
    this.props.onGetStGenDataOps(this.state.LoggedinToken);
    this.props.onGetStSpecDataOps(this.state.LoggedinToken);
    this.props.onGetFacilityDataOps(this.state.LoggedinToken);
    this.props.onReset();
  };

  componentWillUnmount() {
    this.props.onReset();
  }

  Feehandlechange(event) {
    this.setState({
      changed: true,
      Fee: event.target.value,
    });
  }

  SelectClassChange = (value) => {
    // console.log(value);
    this.setState({ selectClass: `${value}`, changed: true }, () => {
      // console.log(this.state.selectClass);
    });
  };

  handleCheckboxChange(event) {
    this.setState({
      is_activity: !this.state.is_activity,
      changed: true,
    });
  }

  onChange = (feeGroupAmounts) => {
    // console.log("changing amount..", feeGroupAmounts);
    this.setState({
      feeGroupAmounts,
      changed: true,
    });
    this.setState({
      fees_amounts: Object.keys(feeGroupAmounts).map((aKey) => {
        return {
          fee_payment_idx: aKey,
          class_idx: this.state.selectClass,
          amount: feeGroupAmounts[aKey].amount,
        };
      }),
      changed: true,
    });
  };

  submitButton(event) {
    event.preventDefault();
    let Data = {};
    if (this.state.Fee !== "" && this.state.Fee !== 0) {
      Data.fee = this.state.Fee;
    }
    if (this.state.selectCategory !== "" && this.state.selectCategory !== 0) {
      Data.category = this.state.selectCategory;
    }
    if (this.state.selectHost !== "" && this.state.selectHost !== 0) {
      Data.host = this.state.selectHost;
    }
    if (this.state.startDate !== "" && this.state.startDate !== 0) {
      Data.start_date = moment(this.state.startDate).format("YYYY-MM-DD");
    }
    if (this.state.endDate !== "" && this.state.endDate !== 0) {
      Data.end_date = moment(this.state.endDate).format("YYYY-MM-DD");
    }
    if (this.state.fees_amounts !== "" && this.state.fees_amounts !== 0) {
      Data.fees_amounts = this.state.fees_amounts.filter(
        (fee_amount) => !(fee_amount.amount === "0" || fee_amount.amount === "")
      );
    }
    if (this.state.LoggedinToken !== "" && this.state.LoggedinToken !== 0) {
      Data.token = this.state.LoggedinToken;
    }
    Data.is_activity = this.state.is_activity;

    if (!this.state.changed) {
      this.setState({ validationError: "Make change to submit" });
    } else if (this.state.Fee === "") {
      this.setState({ validationError: "Feename is required" });
    } else {
      this.props.onEdit(Data);
    }
  }

  startDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  endDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  editButtonHandler(event) {
    event.preventDefault();
    // console.log("Edit Button Clicked");
    this.setState((prevState) => ({ edit: !prevState.edit }));
  }

  deleteButtonHandler(event) {
    event.preventDefault();
    // console.log("Delete Button Clicked");
    const data = {
      feeIdx: this.state.feeTypeIdx,
      token: this.state.LoggedinToken,
    };
    this.props.onDelete(data);
  }

  backButtonHandler(event) {
    event.preventDefault();
    this.setState({ backClicked: true });
  }
  success = () => {
    message.success("Edit Successfull..", 5);
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect to="/login-page" />;
    }
    if (
      !this.props.PaymentTypesData ||
      !(
        this.props.studentGenfees ||
        this.props.studentSpecfees ||
        this.props.facilityfees
      )
    ) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

      return <Spin indicator={antIcon} />;
    }
    if (this.props.isUnauthorised) {
      this.props.resetToken();
      this.props.resetStatus();
      this.props.history.push("/login-page");
      window.location.reload();
    }

    if (this.props.added) {
      this.props.onResetFeelist();
      this.success();
      this.props.history.push("/fees/");
    }
    if (this.state.backClicked) {
      return <Redirect to="/fees/" />;
    }

    return (
      <Grid fluid>
        <Row style={{ marginTop: "20px" }}>
          <Col sm={12} mt={3}>
            <Button
              style={{ marginBottom: "5px" }}
              onClick={(event) => this.backButtonHandler(event)}
            >
              <img src={backIcon} alt="bc" />
              BACK
            </Button>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                // title="Edit/Delete Feetype"
                content={
                  <div>
                    {this.state.selectHost === "1" &&
                    this.state.selectCategory === "1" ? (
                      <h4
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                          color: "#577360",
                        }}
                      >
                        Edit/Delete Student-General Fee
                      </h4>
                    ) : null}
                    {this.state.selectHost === "1" &&
                    this.state.selectCategory === "2" ? (
                      <h4
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                          color: "#577360",
                        }}
                      >
                        Edit/Delete Student-Specified Fee
                      </h4>
                    ) : null}
                    {this.state.selectHost === "2" &&
                    this.state.selectCategory === "1" ? (
                      <h4
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                          color: "#577360",
                        }}
                      >
                        Edit/Delete Facility-Hire Fee
                      </h4>
                    ) : null}
                    <Row>
                      <FormGroup>
                        <Col sm={2}>
                          <ControlLabel>Fee Name:</ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <FormControl
                            placeholder="Enter fee name"
                            value={this.state.Fee}
                            type="text"
                            name="feetype"
                            disabled={!this.state.edit}
                            onChange={(event) => this.Feehandlechange(event)}
                          />
                        </Col>
                      </FormGroup>
                    </Row>
                    {this.state.selectCategory === "2" ? (
                      <Row style={{ marginTop: "5px" }}>
                        <FormGroup>
                          <Col sm={2}>
                            <ControlLabel>Select Class:</ControlLabel>
                          </Col>
                          <Col sm={10}>
                            <Select
                              // key={`specific-fee-select${this.state.selectClass}`}
                              defaultValue={this.state.selectClass}
                              style={{ width: "100%" }}
                              disabled={!this.state.edit}
                              onChange={this.SelectClassChange}
                            >
                              {this.props.ClassData.map((classes) => (
                                <Option
                                  key={classes.class_idx}
                                  value={classes.class_idx}
                                >
                                  {classes.name}
                                </Option>
                              ))}
                            </Select>
                          </Col>
                        </FormGroup>
                      </Row>
                    ) : null}
                    <FormGroup></FormGroup>
                    <FeeGroup
                      data={this.state.feeGroupAmounts} // [{id:this.props.key, amount: 200}]
                      onChange={this.onChange}
                      disabled={this.state.edit}
                    />
                    <Row>
                      <FormGroup>
                        <Col sm={2}>
                          <ControlLabel>Start date</ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <Datetime
                            timeFormat={false}
                            inputProps={{ disabled: !this.state.edit }}
                            dateFormat="DD-MM-YYYY"
                            value={this.state.startDate}
                            selected={this.state.startDate}
                            onChange={this.startDateChange}
                          />
                        </Col>
                      </FormGroup>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                      <FormGroup>
                        <Col sm={2}>
                          <ControlLabel>End date</ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <Datetime
                            timeFormat={false}
                            inputProps={{ disabled: !this.state.edit }}
                            dateFormat="DD-MM-YYYY"
                            value={this.state.endDate}
                            selected={this.state.endDate}
                            disabled={!this.state.edit}
                            onChange={this.endDateChange}
                          />
                        </Col>
                      </FormGroup>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                      <FormGroup>
                        <Col sm={2}>
                          <ControlLabel>Is Activity Fee?: </ControlLabel>
                        </Col>
                        <Col sm={10}>
                          <input
                            name="is_activity"
                            type="checkbox"
                            checked={this.state.is_activity}
                            disabled={!this.state.edit}
                            onChange={this.handleCheckboxChange} />
                        </Col>
                      </FormGroup>
                    </Row>
                    {this.state.validationError !== "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        <img
                          src={errorIcon}
                          alt="err"
                          style={{ maxHeight: "30px", maxWidth: "30px" }}
                        />
                        {this.state.validationError}
                      </span>
                    ) : null}
                  </div>
                }
                legend={
                  <div>
                    <Button
                      bsStyle="info"
                      fill
                      wd
                      onClick={(event) => this.editButtonHandler(event)}
                    >
                      Edit
                    </Button>
                    <Button
                      bsStyle="info"
                      fill
                      wd
                      style={{ marginLeft: "5px" }}
                      onClick={(event) => this.deleteButtonHandler(event)}
                    >
                      Delete
                    </Button>
                    {this.state.edit ? (
                      <div>
                        <Button
                          bsStyle="info"
                          fill
                          wd
                          style={{ marginTop: "5px" }}
                          onClick={(event) => this.submitButton(event)}
                        >
                          Submit
                        </Button>
                      </div>
                    ) : null}
                  </div>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: !!state.auth.token,
    ClassData: state.getFeeOps.classesData,
    PaymentTypesData: state.getFeeOps.PaymentTypeData,
    added: state.addfee.Status === 200,
    studentGenfees: state.getFeeOps.studentGenFees,
    studentSpecfees: state.getFeeOps.studentSpecFees,
    studentGenfeesHead: state.getFeeOps.studentGenFeesHead,
    studentSpecfeesHead: state.getFeeOps.studentSpecFeesHead,
    isUnauthorised:
      (state.getFeeOps.feeStatus ||
        state.getFeeOps.studentGenStatus ||
        state.getFeeOps.studentSpecStatus) === 401,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetStGenDataOps: (LoggedinToken) => {
      dispatch(actions.getDataOps(LoggedinToken));
      dispatch(actions.getFeeOps(LoggedinToken));
    },
    onGetStSpecDataOps: (LoggedinToken) =>
      dispatch(actions.getDataSpecOps(LoggedinToken)),
    onGetFacilityDataOps: (LoggedinToken) =>
      dispatch(actions.getDataFacilityOps(LoggedinToken)),
    onEdit: (Data) => dispatch(actions.editFee(Data)),
    onDelete: (data) => dispatch(actions.deleteFee(data)),
    onReset: () => dispatch(actions.statReset()),
    onResetFeelist: () => dispatch(actions.resetFeelist()),
    resetToken: () => dispatch(actions.resetToken()),
    resetStatus: () => dispatch(actions.resetStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Addfee));
