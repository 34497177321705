import React, { Component } from "react";
import * as actions from "../../Store/Actions/Index";
import { Redirect } from "react-router";
import { connect } from "react-redux";

class Logout extends Component {
  state = {
    LoggedinToken: this.props.logintoken,
  };

  componentDidMount() {
    this.props.onLogout(this.state.LoggedinToken);
  }

  render() {
    // console.log(this.props.Status);
    return <Redirect to="/login-page" />;
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    Status: state.auth.Status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (LoggedinToken) => dispatch(actions.authLogout(LoggedinToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
