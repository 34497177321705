import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Grid,
  Row,
  Col,
  Button,
  ButtonGroup,
  FormGroup,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import * as actions from "../../Store/Actions/Index";
import Card from "components/Card/Card.jsx";
import { Redirect, Switch, Route } from "react-router-dom";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import DatewiseIndividualReport from "./DatewiseIndividualReport";
import '../../assets/css/datepicker.css';
import {Modal} from 'antd';

class DateWiseReport extends Component {
  state = {
    LoggedinToken: this.props.logintoken,
    tbValue: 1,
    studentReportFrom: "",
    facilityReportFrom: "",
    studentReportTo: "",
    facilityReportTo: "",
    sortedData: [],
  };

  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
  }

  componentDidMount() {
    this.props.onGetStudentFeePaid(this.state.LoggedinToken);
    this.props.onGetFacilityFeePaid(this.state.LoggedinToken);
  }

  buttonOne = () => {
    this.setState({ tbValue: 1 }, () => {});
  };

  buttonTwo = () => {
    this.setState({ tbValue: 2 }, () => {});
  };

  fromDateChange = (date) => {
    if (this.state.tbValue === 1) {
      this.setState({
        studentReportFrom: date,
      });
    }
    if (this.state.tbValue === 2) {
      this.setState({
        facilityReportFrom: date,
      });
    }
  };

  toDateChange = (date) => {
    if (this.state.tbValue === 1) {
      this.setState({
        studentReportTo: date,
      });
      // const reportData = {
      //   LoggedinToken: this.state.LoggedinToken,
      //   from_date: moment(this.state.studentReportFrom).format("YYYY-MM-DD"),
      //   to_date: moment(date).format("YYYY-MM-DD"),
      // };
      // // console.log(reportData);
      // this.props.getStudentReportFrom(reportData);
    }
    if (this.state.tbValue === 2) {
      this.setState({
        facilityReportTo: date,
      });
      // const reportData = {
      //   LoggedinToken: this.state.LoggedinToken,
      //   from_date: moment(this.state.facilityReportFrom).format("YYYY-MM-DD"),
      //   to_date: moment(date).format("YYYY-MM-DD"),
      // };
      // console.log(reportData);
      // this.props.getFacilityReportFrom(reportData);
    }
  };

  onDateSubmitButtonClicked = () => {
    if (this.state.tbValue === 1) {
      const reportData = {
        LoggedinToken: this.state.LoggedinToken,
        from_date: moment(this.state.studentReportFrom).format("YYYY-MM-DD"),
        to_date: moment(this.state.studentReportTo).format("YYYY-MM-DD"),
      };
      // console.log(reportData);
      if (this.state.studentReportFrom <= this.state.studentReportTo) {
        this.props.getStudentReportFrom(reportData);
      } else {
        this.error();
        // alert("From date should be lesser than To date");
      }
    }
    if (this.state.tbValue === 2) {
      const reportData = {
        LoggedinToken: this.state.LoggedinToken,
        from_date: moment(this.state.facilityReportFrom).format("YYYY-MM-DD"),
        to_date: moment(this.state.facilityReportTo).format("YYYY-MM-DD"),
      };
      if (this.state.facilityReportFrom <= this.state.facilityReportTo) {
        this.props.getFacilityReportFrom(reportData);
      } else {
        this.error();
        // alert("From date should be lesser than To date");
      }
    }
  };

  onTableViewChange = () => {
    const current = this.reactTable.current;
    if (current) {
      const filteredData = current.getResolvedState().sortedData;
      const sorted = filteredData.map((filtered) => filtered._original);
      this.setState({ sortedData: sorted });
    }
  };

  refreshButtonHandler = () => {
    window.location.reload();
  };

  myStudentCell = ({ value, row, column }) => {
    // console.log(row);
    return (
      <Link
        to={{
          pathname: `/List/datewiseReport/datewiseIndividualReport/${row.QID}`,
          search: `?host=${1}`,
        }}
        key={`cell-${row.index}-${column.id}`}
      >
        {value}{" "}
      </Link>
    );
  };

  myFacilityCell = ({ value, row, column }) => {
    // console.log(row);
    return (
      <Link
        to={{
          pathname: `/List/datewiseReport/datewiseIndividualReport/${row.QID}`,
          search: `?host=${2}`,
        }}
        key={`cell-${row.index}-${column.id}`}
      >
        {value}{" "}
      </Link>
    );
  };

  error = () => {
    Modal.error({
      title: 'ERROR!!',
      content: 'From date should be lesser than To date',
    });
  }

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect to="/login-page" />;
    }

    let studentItems = [];
    let studentData = [];
    let facilityItems = [];
    let facilityData = [];

    if (this.props.studentReportDateFiltered.customers) {
      studentItems = this.props.studentReportDateFiltered.customers.map(
        (item) => {
          return item.transaction.map((transactionItem) => {
            return transactionItem.items.map((aItem) => {
              return {
                QID: item.qid,
                CustomerName: item.fullname,
                CustomerClass: transactionItem.class,
                TransactionDate: moment(
                  transactionItem.transaction_date,
                  "YYYY-MM-DD  HH:mm:ss"
                ).format("MMM DD-YYYY"),
                TotalAmount: transactionItem.totalamount,
                FeeName: aItem.fee_name,
                PaymentType: aItem.fee_payment,
                Amount: aItem.amount,
                Gateway: transactionItem.gateway,
                Status: transactionItem.status,
              };
            });
          });
        }
      );
      studentItems = studentItems.flat();
      for (let i = 0; i < studentItems.length; i++) {
        studentData.push(...studentItems[i]);
      }
    }

    // console.log(studentItems);
    // console.log(studentData);

    if (this.props.facilityReportDateFiltered.customers) {
      facilityItems = this.props.facilityReportDateFiltered.customers.map(
        (item) => {
          return item.transaction.map((transactionItem) => {
            return transactionItem.items.map((aItem) => {
              return {
                QID: item.qid,
                CustomerName: item.fullname,
                TransactionDate: moment(
                  transactionItem.transaction_date,
                  "YYYY-MM-DD  HH:mm:ss"
                ).format("MMM DD-YYYY"),
                TotalAmount: transactionItem.totalamount,
                FeeName: aItem.fee_name,
                Gateway: transactionItem.gateway,
                Status: transactionItem.status,
                Referalno: item.referral_number,
              };
            });
          });
        }
      );
      facilityItems = facilityItems.flat();
      for (let i = 0; i < facilityItems.length; i++) {
        facilityData.push(...facilityItems[i]);
      }
    }

    // console.log(facilityItems);
    // console.log(facilityData)

    const filterCaseInsensitive = ({ id, value }, row) =>
      row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    return (
      <Switch>
        <Route
          path="/List/datewiseReport/datewiseIndividualReport/:QID"
          component={DatewiseIndividualReport}
        />
        <Route path="/List/datewiseReport">
          <div>
            <ButtonGroup
              size="sm"
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: "10px",
              }}
            >
              <Button
                name="button"
                bsStyle={"primary"}
                onClick={this.buttonOne}
              >
                Students Paid
              </Button>
              <Button
                name="button"
                bsStyle={"primary"}
                onClick={this.buttonTwo}
              >
                Facility-Hire Paid
              </Button>
            </ButtonGroup>
            <Button
              className="dark"
              style={{
                marginLeft: 200,
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={this.refreshButtonHandler}
            >
              <i className="fa fa-refresh" aria-hidden="true"></i>Refresh
            </Button>
            <Button
              className="pull-right dark"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                marginRight: "10px",
              }}
            >
              {this.state.tbValue === 1 && this.state.sortedData.length > 0 ? (
                <CSVLink
                  data={this.state.sortedData}
                  filename={"studentreport.csv"}
                >
                  Export
                </CSVLink>
              ) : this.state.tbValue === 2 &&
                this.state.sortedData.length > 0 ? (
                <CSVLink
                  data={this.state.sortedData}
                  filename={"facilityreport.csv"}
                >
                  Export
                </CSVLink>
              ) : this.state.tbValue === 1 && studentData.length > 0 ? (
                <CSVLink data={studentData} filename={"studentreport.csv"}>
                  Export
                </CSVLink>
              ) : this.state.tbValue === 2 && facilityData.length > 0 ? (
                <CSVLink data={facilityData} filename={"facilityreport.csv"}>
                  Export
                </CSVLink>
              ) : null}
            </Button>
            {this.state.tbValue === 1 ? (
              <div className="main-content">
                <Grid fluid>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col sm={6}>
                          <h3 style={{ color: "#577360" }}>Students Paid</h3>
                        </Col>
                        <Col sm={6}>
                          <Row>
                            <Col sm={4} style={{ marginTop: 5 }}>
                              <FormGroup>
                                <DatePicker
                                  placeholderText="From"
                                  dateFormat="dd-MM-yyyy"
                                  value={this.state.studentReportFrom}
                                  selected={this.state.studentReportFrom}
                                  onChange={this.fromDateChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={4} style={{ marginTop: 5 }}>
                              <FormGroup>
                                <DatePicker
                                  placeholderText="To"
                                  dateFormat="dd-MM-yyyy"
                                  value={this.state.studentReportTo}
                                  selected={this.state.studentReportTo}
                                  onChange={this.toDateChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={4}>
                              <Button
                                onClick={this.onDateSubmitButtonClicked}
                                className="pull-right dark"
                              >
                                RETRIEVE
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Card
                        content={
                          <ReactTable
                            ref={this.reactTable}
                            onPageChange={this.onTableViewChange}
                            onPageSizeChange={this.onTableViewChange}
                            onSortedChange={this.onTableViewChange}
                            onExpandedChange={this.onTableViewChange}
                            onFilteredChange={this.onTableViewChange}
                            onResizedChange={this.onTableViewChange}
                            data={studentData}
                            filterable
                            defaultFilterMethod={filterCaseInsensitive}
                            columns={[
                              {
                                Header: "QID",
                                accessor: "QID",
                                Cell: this.myStudentCell,
                              },
                              {
                                Header: "CustomerName",
                                accessor: "CustomerName",
                              },
                              {
                                Header: "CustomerClass",
                                accessor: "CustomerClass",
                              },
                              {
                                Header: "TransactionDate",
                                accessor: "TransactionDate",
                              },
                              {
                                Header: "TotalAmount",
                                accessor: "TotalAmount",
                                Cell: (row) => (
                                  <div
                                    style={{
                                      textAlign: "right",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {row.value}
                                  </div>
                                ),
                              },
                              {
                                Header: "FeeName",
                                accessor: "FeeName",
                              },
                              {
                                Header: "PaymentType",
                                accessor: "PaymentType",
                              },
                              {
                                Header: "Amount",
                                accessor: "Amount",
                                Cell: (row) => (
                                  <div
                                    style={{
                                      textAlign: "right",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {row.value}
                                  </div>
                                ),
                              },
                              {
                                Header: "Gateway",
                                accessor: "Gateway",
                              },
                              {
                                Header: "Status",
                                accessor: "Status",
                              },
                            ]}
                            defaultPageSize={10}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                          />
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
              </div>
            ) : this.state.tbValue === 2 ? (
              <div className="main-content">
                <Grid fluid>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col sm={6}>
                          <h3 style={{ color: "#577360" }}>
                            Facility-Hire Paid
                          </h3>
                        </Col>
                        <Col sm={6}>
                          <Row>
                            <Col sm={4} style={{ marginTop: 5 }}>
                              <FormGroup>
                                <DatePicker
                                  placeholderText="From"
                                  dateFormat="dd-MM-yyyy"
                                  value={this.state.facilityReportFrom}
                                  selected={this.state.facilityReportFrom}
                                  onChange={this.fromDateChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={4} style={{ marginTop: 5 }}>
                              <FormGroup>
                                <DatePicker
                                  placeholderText="To"
                                  dateFormat="dd-MM-yyyy"
                                  value={this.state.facilityReportTo}
                                  selected={this.state.facilityReportTo}
                                  onChange={this.toDateChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={4}>
                              <Button
                                onClick={this.onDateSubmitButtonClicked}
                                className="pull-right dark"
                              >
                                RETRIEVE
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Card
                        content={
                          <ReactTable
                            ref={this.reactTable}
                            onPageChange={this.onTableViewChange}
                            onPageSizeChange={this.onTableViewChange}
                            onSortedChange={this.onTableViewChange}
                            onExpandedChange={this.onTableViewChange}
                            onFilteredChange={this.onTableViewChange}
                            onResizedChange={this.onTableViewChange}
                            data={facilityData}
                            filterable
                            defaultFilterMethod={filterCaseInsensitive}
                            columns={[
                              {
                                Header: "QID",
                                accessor: "QID",
                                Cell: this.myFacilityCell,
                              },
                              {
                                Header: "CustomerName",
                                accessor: "CustomerName",
                              },
                              {
                                Header: "TransactionDate",
                                accessor: "TransactionDate",
                              },
                              {
                                Header: "TotalAmount",
                                accessor: "TotalAmount",
                                Cell: (row) => (
                                  <div
                                    style={{
                                      textAlign: "right",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {row.value}
                                  </div>
                                ),
                              },
                              {
                                Header: "FeeName",
                                accessor: "FeeName",
                              },
                              {
                                Header: "ReferenceID",
                                accessor: "ReferenceID",
                              },
                              {
                                Header: "Gateway",
                                accessor: "Gateway",
                              },
                              {
                                Header: "Status",
                                accessor: "Status",
                              },
                            ]}
                            defaultPageSize={10}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                          />
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
              </div>
            ) : null}
          </div>
        </Route>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    studentReportDateFiltered: state.getFeeOps.studentDateFiltered,
    facilityReportDateFiltered: state.getFeeOps.facilityDateFiltered,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentReportFrom: (reportData) =>
      dispatch(actions.getStudentReportFromData(reportData)),
    getFacilityReportFrom: (reportData) =>
      dispatch(actions.getFacilityReportFromData(reportData)),
    onGetStudentFeePaid: (LoggedinToken) =>
      dispatch(actions.getStudentFeePaid(LoggedinToken)),
    onGetFacilityFeePaid: (LoggedinToken) =>
      dispatch(actions.getFacilityFeePaid(LoggedinToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateWiseReport);
