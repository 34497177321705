import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import * as actions from "../../Store/Actions/Index";
import moment from "moment";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Button from "components/CustomButton/CustomButton.jsx";
import backIcon from '../../assets/img/back.png';

class DatewiseIndividualReport extends Component {
  state = {
    LoggedinToken: this.props.logintoken,
    Qid: "",
    Host: "",
    backClicked: false,
  };
  componentDidMount() {
    // console.log("individual report");
    const urlParams = new URLSearchParams(this.props.location.search);
    const hostData = urlParams.get("host");
    if (hostData) {
      this.setState({ Host: hostData });
    }
    this.setState({ Qid: this.props.match.params.QID }, () => {
      // console.log(this.state.Qid);
    });
  }

  backButtonHandler(event) {
    event.preventDefault();
    this.setState({ backClicked: true });
  }

  render() {
    //  console.log(this.props.studentsPaid);
    // console.log(this.props.facilityPaid);

    if (!this.props.isAuthenticated) {
      return <Redirect to="/login-page" />;
    }
    if (
      !this.props.studentsPaid ||
      !this.props.facilityPaid ||
      !this.state.Host
    ) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return <Spin indicator={antIcon} />;
    }
    if (this.props.isUnauthorised) {
      this.props.resetToken()
      this.props.resetStatus()
      this.props.history.push("/login-page")
      window.location.reload()
    }

    if (this.state.backClicked) {
      return <Redirect to="/List/datewiseReport/" />;
    }
    
    let data = [];
    if (this.state.Host === "1") {
      data = this.props.studentsPaid.customers.filter((dt) => this.state.Qid === dt.qid);
    }
    if (this.state.Host === "2") {
      data = this.props.facilityPaid.customers.filter((dt) => this.state.Qid === dt.qid);
    }

    let dataItems = data.map((item) => {
      return item.transaction.map((transactionItem) => {
        return transactionItem.items.map((aItem) => {
          return {
            Qid: item.qid,
            customerName: item.fullname,
            customerEmail: item.email,
            customerPhone: item.phone,
            customerClass: transactionItem.class,
            transactionDate: moment(
              transactionItem.transaction_date,
              "YYYY-MM-DD  HH:mm:ss"
            ).format("MMM DD-YYYY HH:MM:SS"),
            totalAmount: transactionItem.totalamount,
            feeName: aItem.fee_name,
            paymentType: aItem.fee_payment,
            amount: aItem.amount,
            pun: transactionItem.pun,
            gateway: transactionItem.gateway,
            status: transactionItem.status,
          };
        });
      });
    });
    dataItems = dataItems.flat();
    //console.log(dataItems)

    let Data = [];
    for (let i = 0; i < dataItems.length; i++) {
      Data.push(...dataItems[i]);
    }
    //console.log(Data);

    const filterCaseInsensitive = ({ id, value }, row) =>
      row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    return (
      <div>
        <Grid fluid>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12} mt={3}>
              <Button
                style={{ marginBottom: "5px" }}
                onClick={(event) => this.backButtonHandler(event)}
              >
                <img src={backIcon} alt="bc"/>
                BACK
              </Button>
              <Card
                textCenter
                title="Individual report"
                content={
                  <div>
                    <div>
                      <h5>QID: {this.state.Qid}</h5>
                      {/* <h5>
                        Payment Unique Number:{" "}
                        {dataItems.length > 0
                          ? dataItems[0][0].pun
                          : ""}
                      </h5> */}
                      <h5>
                        Customer Name:{" "}
                        {dataItems.length > 0
                          ? dataItems[0][0].customerName
                          : ""}
                      </h5>
                      <h5>
                        Customer Email:{" "}
                        {dataItems.length > 0
                          ? dataItems[0][0].customerEmail
                          : ""}
                      </h5>
                      <h5>
                        Customer Phone:{" "}
                        {dataItems.length > 0
                          ? dataItems[0][0].customerPhone
                          : ""}
                      </h5>{" "}
                      <h5>
                        Total Amount:{" "}
                        {dataItems.length > 0
                          ? dataItems[0][0].totalAmount
                          : ""}
                      </h5>
                      <ReactTable
                        data={Data}
                        filterable
                        defaultFilterMethod={filterCaseInsensitive}
                        columns={[
                          {
                            Header: "FeeName",
                            accessor: "feeName",
                          },
                          {
                            Header: "PaymentType",
                            accessor: "paymentType",
                          },
                          {
                            Header: "PUN",
                            accessor: "pun",
                          },
                          {
                            Header: "Gateway",
                            accessor: "gateway",
                          },
                          {
                            Header: "CustomerClass",
                            accessor: "customerClass",
                          },
                          {
                            Header: "Amount",
                            accessor: "amount",
                          },
                          {
                            Header: "TransactionDate",
                            accessor: "transactionDate",
                          },
                          {
                            Header: "Status",
                            accessor: "status",
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    studentsPaid: state.getFeeOps.studentDateFiltered,
    //studentsPaid: state.getFeeOps.studentCustomerData,
    //facilityPaid: state.getFeeOps.facilityDateFiltered,
    facilityPaid: state.getFeeOps.facilityCustomerData,
    isUnauthorised: (state.getFeeOps.studentReportStatus || state.getFeeOps.facilityReportStatus) === 401,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetToken: () => dispatch(actions.resetToken()),
    resetStatus: () => dispatch(actions.resetStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DatewiseIndividualReport));
