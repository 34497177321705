import React, { Component } from "react";
import ReactTable from "react-table";
import { Grid, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as actions from "../../Store/Actions/Index";
import Card from "components/Card/Card.jsx";
import { withRouter, Redirect, Switch, Route } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import IndividualReport from "./IndividualReport";
import { CSVLink } from "react-csv";

class StudentList extends Component {
  state = {
    LoggedinToken: this.props.logintoken,
    tbValue: 1,
    sortedData: [],
  };

  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
  }

  componentDidMount() {
    this.props.onGetStudentFeePaid(this.state.LoggedinToken);
    this.props.onGetFacilityFeePaid(this.state.LoggedinToken);
  }

  buttonOne = () => {
    this.setState({ tbValue: 1 }, () => {});
  };

  buttonTwo = () => {
    this.setState({ tbValue: 2 }, () => {});
  };

  myStudentCell = ({ value, row, column }) => {
    // console.log(row);
    return (
      <Link
        to={{
          pathname: `/List/report/individualReport/${row.QID}`,
          search: `?host=${1}`,
        }}
        key={`cell-${row.index}-${column.id}`}
      >
        {value}{" "}
      </Link>
    );
  };

  myFacilityCell = ({ value, row, column }) => {
    // console.log(row);
    return (
      <Link
        to={{
          pathname: `/List/report/individualReport/${row.QID}`,
          search: `?host=${2}`,
        }}
        key={`cell-${row.index}-${column.id}`}
      >
        {value}{" "}
      </Link>
    );
  };

  onTableViewChange = () => {
    const current = this.reactTable.current;
    if (current) {
      const filteredData = current.getResolvedState().sortedData;
      const sorted = filteredData.map((filtered) => filtered._original);
      this.setState({ sortedData: sorted });
    }
  };

  refreshButtonHandler = () => {
    window.location.reload();
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect to="/login-page" />;
    }
    if (!this.props.studentsPaid || !this.props.facilityPaid) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return <Spin indicator={antIcon} />;
    }
    if (this.props.isUnauthorised) {
      this.props.resetToken();
      this.props.resetStatus();
      this.props.history.push("/login-page");
      window.location.reload();
    }

    let studentItems = [];
    if (this.props.studentsPaid.customers) {
      studentItems = this.props.studentsPaid.customers.map((item) => {
        return item.transaction.map((transactionItem) => {
          return transactionItem.items.map((aItem) => {
            return {
              QID: item.qid,
              CustomerName: item.fullname,
              CustomerClass: transactionItem.class,
              TransactionDate: moment(
                transactionItem.transaction_date,
                "YYYY-MM-DD  HH:mm:ss"
              ).format("DD-MMM-YYYY  HH:mm:ss"),
              TotalAmount: transactionItem.totalamount,
              FeeName: aItem.fee_name,
              PaymentType: aItem.fee_payment,
              Amount: aItem.amount,
              Gateway: transactionItem.gateway,
              Status: transactionItem.status,
            };
          });
        });
      });
      studentItems = studentItems.flat();
    }
    // console.log(studentItems);

    let studentData = [];
    for (let i = 0; i < studentItems.length; i++) {
      studentData.push(...studentItems[i]);
    }
    // console.log(studentData);

    let facilityItems = [];
    if (this.props.facilityPaid.customers) {
      facilityItems = this.props.facilityPaid.customers.map((item) => {
        return item.transaction.map((transactionItem) => {
          return transactionItem.items.map((aItem) => {
            return {
              QID: item.qid,
              CustomerName: item.fullname,
              TransactionDate: moment(
                transactionItem.transaction_date,
                "YYYY-MM-DD  HH:mm:ss"
              ).format("DD-MMM-YYYY  HH:mm:ss"),
              TotalAmount: transactionItem.totalamount,
              FeeName: aItem.fee_name,
              ReferenceID: item.referral_number,
              Gateway: transactionItem.gateway,
              Status: transactionItem.status,
            };
          });
        });
      });
      facilityItems = facilityItems.flat();
    }
    // console.log(facilityItems);

    let facilityData = [];
    for (let i = 0; i < facilityItems.length; i++) {
      facilityData.push(...facilityItems[i]);
    }

    const filterCaseInsensitive = ({ id, value }, row) =>
      row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    return (
      <Switch>
        <Route
          path="/List/report/individualReport/:QID"
          component={IndividualReport}
        />
        <Route path="/List/report">
          <div>
            <ButtonGroup
              size="sm"
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: "10px",
              }}
            >
              <Button
                name="button"
                bsStyle={"primary"}
                onClick={this.buttonOne}
              >
                Students Paid
              </Button>
              <Button
                name="button"
                bsStyle={"primary"}
                onClick={this.buttonTwo}
              >
                Facility-Hire Paid
              </Button>
            </ButtonGroup>
            <Button
              className="dark"
              style={{
                marginLeft: 200,
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={this.refreshButtonHandler}
            >
              <i className="fa fa-refresh" aria-hidden="true"></i>Refresh
            </Button>
            <Button
              className="pull-right dark"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                marginRight: "10px",
              }}
            >
              {this.state.tbValue === 1 && this.state.sortedData.length > 0 ? (
                <CSVLink
                  data={this.state.sortedData}
                  filename={"studentreport.csv"}
                >
                  Export
                </CSVLink>
              ) : this.state.tbValue === 2 &&
                this.state.sortedData.length > 0 ? (
                <CSVLink
                  data={this.state.sortedData}
                  filename={"facilityreport.csv"}
                >
                  Export
                </CSVLink>
              ) : this.state.tbValue === 1 && studentData.length > 0 ? (
                <CSVLink data={studentData} filename={"studentreport.csv"}>
                  Export
                </CSVLink>
              ) : this.state.tbValue === 2 && facilityData.length > 0 ? (
                <CSVLink data={facilityData} filename={"Facilityreport.csv"}>
                  Export
                </CSVLink>
              ) : null}
            </Button>
            {this.state.tbValue === 1 ? (
              <div className="main-content">
                <Grid fluid>
                  <Row>
                    <Col md={12}>
                      <h3 style={{ color: "#577360" }}>Students Paid</h3>
                      <Card
                        content={
                          <ReactTable
                            ref={this.reactTable}
                            onPageChange={this.onTableViewChange}
                            onPageSizeChange={this.onTableViewChange}
                            onSortedChange={this.onTableViewChange}
                            onExpandedChange={this.onTableViewChange}
                            onFilteredChange={this.onTableViewChange}
                            onResizedChange={this.onTableViewChange}
                            data={studentData}
                            filterable
                            defaultFilterMethod={filterCaseInsensitive}
                            columns={[
                              {
                                Header: "QID",
                                accessor: "QID",
                                Cell: this.myStudentCell,
                              },
                              {
                                Header: "CustomerName",
                                accessor: "CustomerName",
                              },
                              {
                                Header: "CustomerClass",
                                accessor: "CustomerClass",
                              },
                              {
                                Header: "TransactionDate",
                                accessor: "TransactionDate",
                              },
                              {
                                Header: "TotalAmount",
                                accessor: "TotalAmount",
                                Cell: (row) => (
                                  <div
                                    style={{
                                      textAlign: "right",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {row.value}
                                  </div>
                                ),
                              },
                              {
                                Header: "FeeName",
                                accessor: "FeeName",
                              },
                              {
                                Header: "PaymentType",
                                accessor: "PaymentType",
                              },
                              {
                                Header: "Amount",
                                accessor: "Amount",
                                Cell: (row) => (
                                  <div
                                    style={{
                                      textAlign: "right",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {row.value}
                                  </div>
                                ),
                              },
                              {
                                Header: "Gateway",
                                accessor: "Gateway",
                              },
                              {
                                Header: "Status",
                                accessor: "Status",
                              },
                            ]}
                            defaultSorted={[
                              {
                                id: "Transaction Date",
                              },
                            ]}
                            filterAll={true}
                            defaultPageSize={10}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                          />
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
              </div>
            ) : this.state.tbValue === 2 ? (
              <div className="main-content">
                <Grid fluid>
                  <Row>
                    <Col md={12}>
                      <h3 style={{ color: "#577360" }}>Facility-Hire Paid</h3>
                      <Card
                        content={
                          <ReactTable
                            ref={this.reactTable}
                            onPageChange={this.onTableViewChange}
                            onPageSizeChange={this.onTableViewChange}
                            onSortedChange={this.onTableViewChange}
                            onExpandedChange={this.onTableViewChange}
                            onFilteredChange={this.onTableViewChange}
                            onResizedChange={this.onTableViewChange}
                            data={facilityData}
                            filterable
                            defaultFilterMethod={filterCaseInsensitive}
                            columns={[
                              {
                                Header: "QID",
                                accessor: "QID",
                                Cell: this.myFacilityCell,
                              },
                              {
                                Header: "CustomerName",
                                accessor: "CustomerName",
                              },
                              {
                                Header: "TransactionDate",
                                accessor: "TransactionDate",
                              },
                              {
                                Header: "TotalAmount",
                                accessor: "TotalAmount",
                                Cell: (row) => (
                                  <div
                                    style={{
                                      textAlign: "right",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {row.value}
                                  </div>
                                ),
                              },
                              {
                                Header: "FeeName",
                                accessor: "FeeName",
                              },
                              {
                                Header: "Gateway",
                                accessor: "Gateway",
                              },

                              {
                                Header: "Reference ID",
                                accessor: "ReferenceID",
                              },

                              {
                                Header: "Status",
                                accessor: "Status",
                              },
                            ]}
                            defaultSorted={[
                              {
                                id: "Transaction Date",
                              },
                            ]}
                            defaultPageSize={10}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                          />
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
              </div>
            ) : null}
          </div>
        </Route>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    studentsPaid: state.getFeeOps.studentCustomerData,
    facilityPaid: state.getFeeOps.facilityCustomerData,
    isUnauthorised:
      (state.getFeeOps.studentReportStatus ||
        state.getFeeOps.facilityReportStatus) === 401,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetStudentFeePaid: (LoggedinToken) =>
      dispatch(actions.getStudentFeePaid(LoggedinToken)),
    onGetFacilityFeePaid: (LoggedinToken) =>
      dispatch(actions.getFacilityFeePaid(LoggedinToken)),
    resetToken: () => dispatch(actions.resetToken()),
    resetStatus: () => dispatch(actions.resetStatus()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentList));
