import Axios from "../../Axios";

export const addStart = () => {
  return {
    type: "ADD_START",
  };
};

export const addSuccess = (fee_type_idx, stat) => {
  // console.log(stat);
  return {
    type: "ADD_SUCCESS",
    fee_type_idx: fee_type_idx,
    status: stat,
  };
};

export const statReset = () => {
  return {
    type: "STAT_RESET",
  }
}
export const addFail = (error) => {
  return {
    type: "ADD_FAIL",
    error: error,
  };
};

export const addFee = (Data) => {
  return (dispatch) => {
    dispatch(addStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${Data.token}`,
    };
    const AddData = {
      name: Data.fee,
      type_flag: Data.category,
      student_facility: Data.host,
      start_date: Data.start_date,
      end_date: Data.end_date,
      fees_amounts: Data.fees_amounts,
      is_activity: Data.is_activity,
    };
    // console.log(AddData);
    Axios
      .post("/fees_types/add", AddData, {
        headers: Headers,
      })
      .then((response) => {
        // console.log(response.data);
        // console.log(response.status);
        // localStorage.setItem('token', response.data.data.token);
        // dispatch(addSuccess(response.data.token));
        // dispatch(addSuccess(response.status));
        dispatch(addSuccess(response.data.fee_type_idx, response.status));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(addFail(error));
      });
  };
};

export const editStart = () => {
  return {
    type: "EDIT_START",
  };
};

export const editSuccess = (stat) => {
  // console.log(stat);
  return {
    type: "EDIT_SUCCESS",
    status: stat,
  };
};

export const editFail = (error) => {
  return {
    type: "EDIT_FAIL",
    error: error,
  };
};

export const editFee = (Data) => {
  return (dispatch) => {
    dispatch(editStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${Data.token}`,
    };
    // console.log(Data.fees_amounts);
    // console.log(Data.end_date);
    const EditData = {
      name: Data.fee,
      type_flag: Data.category,
      student_facility: Data.host,
      start_date: Data.start_date,
      end_date: Data.end_date,
      fees_amounts: Data.fees_amounts,
      is_activity: Data.is_activity,
    };
    // console.log(EditData);
    Axios
      .post("/fees_types/update", EditData, {
        headers: Headers,
      })
      .then((response) => {
        // console.log(response.data);
        // console.log(response.status);
        // localStorage.setItem('token', response.data.data.token);
        // dispatch(addSuccess(response.data.token));
        // dispatch(addSuccess(response.status));
        dispatch(editSuccess(response.status));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(editFail(error));
      });
  };
};

export const deleteStart = () => {
  return {
    type: "DELETE_START",
  };
};

export const deleteSuccess = (stat) => {
  // console.log(stat);
  return {
    type: "DELETE_SUCCESS",
    status: stat,
  };
};

export const deleteFail = (error) => {
  return {
    type: "DELETE_FAIL",
    error: error,
  };
};

export const deleteFee = (data) => {
  // console.log(data.feeIdx);
  // console.log(data.token);
  return (dispatch) => {
    dispatch(deleteStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${data.token}`,
    };
    const DeleteData = {
      fee_type_idx: data.feeIdx,
    };
    Axios
      .post("/fees_types/delete", DeleteData, {
        headers: Headers,
      })
      .then((response) => {
        // console.log(response.data);
        // console.log(response.status);
        dispatch(deleteSuccess(response.status));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(deleteFail(error));
      });
  };
};
