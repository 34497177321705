import { updatedObject } from "../Utility";

const initialState = {
  academicYear: "",
  error: null,
  getStatus: "",
  updateStatus: "",
};

const getYearStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const getYearSuccess = (state, action) => {
  return updatedObject(state, {
    academicYear: action.year,
    getStatus: action.status,
    error: null,
  });
};

const getYearFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const resetGetStatus = (state, action) => {
  return updatedObject(state, {
    getStatus: "",
  });
};

const updateYearStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const updateYearSuccess = (state, action) => {
  return updatedObject(state, {
    updateStatus: action.status,
    error: null,
  });
};

const updateYearFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const resetUpdateStatus = (state, action) => {
  return updatedObject(state, {
    updateStatus: "",
  });
};

const resetData = (state, action) => {
  return updatedObject(state, {
    academicYear: "",
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_YEAR_START":
      return getYearStart(state, action);
    case "GET_YEAR_SUCCESS":
      return getYearSuccess(state, action);
    case "GET_YEAR_FAIL":
      return getYearFail(state, action);
    case "RESET_GET_STATUS":
      return resetGetStatus(state, action);
    case "UPDATE_YEAR_START":
      return updateYearStart(state, action);
    case "UPDATE_YEAR_SUCCESS":
      return updateYearSuccess(state, action);
    case "UPDATE_YEAR_FAIL":
      return updateYearFail(state, action);
    case "RESET_UPDATE_STATUS":
      return resetUpdateStatus(state, action);
    case "RESET_DATA":
      return resetData(state, action);
    default:
      return state;
  }
};

export default reducer;
