import React, { Component } from "react";
import ReactTable from "react-table";
import { Grid, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as actions from "../../Store/Actions/Index";
import Card from "components/Card/Card.jsx";
import {
  withRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Offer from "./Offers";

class FacilityFeeList extends Component {
  state = {
    LoggedinToken: this.props.logintoken,
  };

  componentDidMount = () => {
    this.props.onGetOfferData(this.state.LoggedinToken);
    this.props.onGetStGenDataOps(this.state.LoggedinToken);
    this.props.onGetStSpecDataOps(this.state.LoggedinToken);
  };

  addButtonHandler = () => {
    this.props.history.push({
      pathname: "/offerList/offerList/addOffers",
    });
  };

  deleteHandler = (CDID) => {
    const deleteData = {
      CDID: CDID,
      LoggedinToken: this.state.LoggedinToken,
    };
    // console.log(deleteData);
    this.props.onDelete(deleteData);
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect to="/login-page" />;
    }
    if (!this.props.offerData) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return <Spin indicator={antIcon} />;
    }
    if (this.props.isUnauthorised) {
      this.props.resetToken()
      this.props.resetStatus()
      this.props.history.push("/login-page")
      window.location.reload();
    }
    // console.log(this.props.successStatus);
    // console.log(this.props.offerData);

    if (this.props.successStatus) {
      window.location.reload();
      this.props.onResetData();
    }

    let accessors = this.props.offerData.map((listItem) => {
      return listItem.deduction_amount.map((deductionItems) => {
        return {
          claimFee: listItem.claim_fee_name,
          claimAmount: listItem.claim_amount,
          claimDeductionId: listItem.claim_deduction_idx,
          deductionFee: listItem.deduction_fee_name,
          deductionType: deductionItems.fee_payment_name,
          deductionAmount: deductionItems.amount,
        };
      });
    });
    accessors = accessors.flat();

    const filterCaseInsensitive = ({ id, value }, row) =>
      row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    return (
      <Switch>
        <Route path="/offerList/offerList/addOffers" component={Offer} />
        <Route path="/offerList/offerList">
          <div className="main-content">
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col sm={8}>
                      <h3 style={{ color: "#577360" }}>Offers</h3>
                    </Col>
                    <Col sm={4}>
                      <Button
                        className="pull-right dark"
                        style={{
                          marginBottom: "20px",
                          marginRight: "10px",
                        }}
                        onClick={this.addButtonHandler}
                      >
                        ADD OFFERS
                      </Button>
                    </Col>
                  </Row>
                  <Card
                    content={
                      <ReactTable
                        data={accessors}
                        filterable
                        defaultFilterMethod={filterCaseInsensitive}
                        columns={[
                          {
                            Header: "Claim Fee",
                            accessor: "claimFee",
                          },
                          {
                            Header: "Cliam Amount",
                            accessor: "claimAmount",
                            Cell: (row) => (
                              <div
                                style={{
                                  textAlign: "right",
                                  marginRight: "20px",
                                }}
                              >
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: "Deduction Fee",
                            accessor: "deductionFee",
                          },
                          {
                            Header: "Deduction Fee Type",
                            accessor: "deductionType",
                            Cell: (row) => (
                              <div
                                style={{
                                  textAlign: "right",
                                  // marginRight: "20px",
                                }}
                              >
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: "Deduction Amount",
                            accessor: "deductionAmount",
                            Cell: (row) => (
                              <div
                                style={{
                                  textAlign: "right",
                                  marginRight: "20px",
                                }}
                              >
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: "Action",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            Cell: (row) => (
                              <div className="actions-right">
                                <Button
                                  bsStyle="danger"
                                  onClick={() => {
                                    let CDID =
                                      accessors[row.index].claimDeductionId;
                                    this.deleteHandler(CDID);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            ),
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </Route>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    offerData: state.offer.OfferData,
    successStatus: state.offer.Status === 200,
    isUnauthorised: state.offer.offerStatus === 401,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetOfferData: (LoggedinToken) => {
      dispatch(actions.getOffer(LoggedinToken));
      dispatch(actions.getFeeOps(LoggedinToken));
    },
    onGetStGenDataOps: (LoggedinToken) => {
      dispatch(actions.getDataOps(LoggedinToken));
    },
    onGetStSpecDataOps: (LoggedinToken) =>
      dispatch(actions.getDataSpecOps(LoggedinToken)),
    onDelete: (deleteData) => dispatch(actions.deleteOffer(deleteData)),
    onResetData: () => dispatch(actions.resetOfferData()),
    resetToken: () => dispatch(actions.resetToken()),
    resetStatus: () => dispatch(actions.resetStatusData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FacilityFeeList));
