import Axios from "../../Axios";

export const getYearStart = () => {
  return {
    type: "GET_YEAR_START",
  };
};

export const getYearSuccess = (yearData, Status) => {
  return {
    type: "GET_YEAR_SUCCESS",
    year: yearData,
    status: Status,
  };
};

export const getYearFail = (error) => {
  return {
    type: "GET_YEAR_FAIL",
    error: error,
  };
};

export const getAcademicYear = (LoggedinToken) => {
  return (dispatch) => {
    dispatch(getYearStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${LoggedinToken}`,
    };
    Axios.get("/payment/getyear", {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.data);
        // console.log(response.status);
        dispatch(getYearSuccess(response.data, response.status));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(getYearFail(error));
      });
  };
};

export const updateYearStart = () => {
  return {
    type: "UPDATE_YEAR_START",
  };
};

export const updateYearSuccess = (stat) => {
  return {
    type: "UPDATE_YEAR_SUCCESS",
    status: stat,
  };
};

export const updateYearFail = (error) => {
  return {
    type: "UPDATE_YEAR_FAIL",
    error: error,
  };
};

export const updateAcademicYear = (updateData) => {
  return (dispatch) => {
    dispatch(updateYearStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${updateData.LoggedinToken}`,
    };
    const UpdateData = {
      aid: updateData.aid,
      year: updateData.academicYear,
    };
    // console.log(UpdateData);
    Axios.post("/payment/setyear", UpdateData, {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.data);
        // console.log(response.status);
        dispatch(updateYearSuccess(response.status));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(updateYearFail(error));
      });
  };
};

export const resetGetStatus = () => {
  return {
    type: "RESET_GET_STATUS",
  };
};

export const resetUpdateStatus = () => {
  return {
    type: "RESET_UPDATE_STATUS",
  };
};

export const resetData = () => {
  return {
    type: "RESET_DATA",
  };
};
