import React, { Component } from "react";
import {
  Row,
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
} from "react-bootstrap";

import { connect } from "react-redux";
// import * as actions from "../../Store/Actions/Index";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

class FeeGroup extends Component {
  componentDidMount() {
    // this.props.onGet(this.props.logintoken);
  }

  onFieldChange = (event, feePaymentIdx) => {
    const fieldValue = event.target.value;
    this.props.onChange({
      ...this.props.data,
      [feePaymentIdx]: { amount: fieldValue },
    });
  };

  render() {
    if (!this.props.PaymentTypesData) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return <Spin indicator={antIcon} />;
    }
    // console.log(this.props.PaymentTypesData);
    // console.log(this.props.logintoken);
    for (let i = 0; i < this.props.PaymentTypesData.length; i++) {
      return (
        <FormGroup>
          <div>
            {this.props.PaymentTypesData.map((Paymenttype) => (
              <Row key={`payment-${Paymenttype.fee_payment_idx}`} style={{ marginTop: "5px" }}>
                <Col sm={2} style={{ marginTop: "5px" }}>
                  <ControlLabel key={Paymenttype.fee_payment_idx}>
                    {Paymenttype.name}:
                  </ControlLabel>
                </Col>
                <Col sm={10}>
                  <FormControl
                    key={Paymenttype.fee_payment_idx}
                    placeholder="Amount"
                    value={
                      this.props.data[Paymenttype.fee_payment_idx]
                        ? this.props.data[Paymenttype.fee_payment_idx].amount
                        : ""
                    }
                    type="number"
                    style={{ width: "100%" }}
                    disabled={this.props.disabled === false}
                    onChange={(event) => {
                      this.onFieldChange(event, Paymenttype.fee_payment_idx);
                    }}
                  />
                </Col>
              </Row>
            ))}
          </div>
        </FormGroup>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    logintoken: state.auth.token,
    isAuthenticated: !!state.auth.token,
    PaymentTypesData: state.getFeeOps.PaymentTypeData,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     // onGet: (LoggedinToken) => dispatch(actions.getFeeOps(LoggedinToken)),
//   };
// };

export default connect(mapStateToProps)(FeeGroup);
