import Axios from "../../Axios";

export const addOfferStart = () => {
  return {
    type: "ADD_OFFER_START",
  };
};

export const addOfferSuccess = (status) => {
  return {
    type: "ADD_OFFER_SUCCESS",
    status: status
  };
};

export const resetOfferStatus = () => {
  return {
    type: "RESET_OFFER_STATUS",
  };
};

export const addOfferFail = () => {
  return {
    type: "ADD_OFFER_FAIL",
  };
};

export const addOffer = (Data) => {
  const addData = {
    claim_table: Data.claimCategory,
    claim_id: Data.claimFee,
    deduction_table: Data.deductCategory,
    deduction_ids: Data.fees_amounts,
    claim_amount: Data.amountClaimmable,
    claim_text: Data.claimText,
    class_idx: Data.deductClass,
  };
  // console.log(addData);
  return (dispatch) => {
    dispatch(addOfferStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${Data.LoggedinToken}`,
    };
    Axios.post("/claimadd", addData, {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.data);
        dispatch(addOfferSuccess(response.status));
      })
      .catch((error) => {
        dispatch(addOfferFail(error));
      });
  };
};
export const getOfferStart = () => {
  return {
    type: "GET_OFFER_START",
  };
};

export const getOfferSuccess = (offerData, status) => {
  return {
    type: "GET_OFFER_SUCCESS",
    listData: offerData,
    status: status,
  };
};

export const resetStatusData = () => {
  return {
    type: "RESET_STATUS"
  }
}

export const getOfferFail = () => {
  return {
    type: "GET_OFFER_FAIL",
  };
};

export const getOffer = (LoggedinToken) => {
  return (dispatch) => {
    dispatch(getOfferStart());
    const Headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${LoggedinToken}`,
    };
    Axios.get("/claimtable", {
      headers: Headers,
    })
      .then((response) => {
        // console.log(response.data);
        dispatch(getOfferSuccess(response.data, response.status));
      })
      .catch((error) => {
        dispatch(getOfferFail(error));
      });
  };
};

export const resetOfferData = () => {
  return {
    type: "RESET_OFFER_DATA",
  };
};

export const deleteOfferStart = () => {
  return {
    type: "DELETE_OFFER_START",
  };
};

export const deleteOfferSuccess = (status) => {
  // console.log(status);
  return {
    type: "DELETE_OFFER_SUCCESS",
    status: status,
  };
};

export const deleteOfferFail = (error) => {
  return {
    type: "DELETE_OFFER_FAIL",
    error: error,
  };
};

export const deleteOffer = (deleteData) => {
  // console.log(deleteData);
  return (dispatch) => {
    dispatch(deleteOfferStart());
    const URL = `/claimdelete/${deleteData.CDID}`;
    return Axios({
      method: "post",
      url: URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${deleteData.LoggedinToken}`,
      },
    })
      .then((response) => {
        // console.log(response.data);
        // console.log(response.status);
        dispatch(deleteOfferSuccess(response.status));
      })
      .catch((error) => {
        dispatch(deleteOfferFail(error));
      });
  };
};
