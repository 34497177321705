import { updatedObject } from "../Utility";

const initialState = {
  error: null,
};

const refundStart = (state, action) => {
  return updatedObject(state, {
    error: null,
  });
};

const refundSuccess = (state, action) => {
  return updatedObject(state, {
    error: null,
    status: action.Status,
  });
};

const refundFail = (state, action) => {
  return updatedObject(state, {
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "REFUND_START":
      return refundStart(state, action);
    case "REFUND_SUCCESS":
      return refundSuccess(state, action);
    case "REFUND_FAIL":
      return refundFail(state, action);
    default:
      return state;
  }
};

export default reducer;
